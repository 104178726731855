"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperatorType = exports.YesNoStatus = exports.ResponseStatuses = exports.AccountStatus = exports.SystemStatus = exports.FileDownloadStatus = void 0;
var FileDownloadStatus;
(function (FileDownloadStatus) {
    FileDownloadStatus[FileDownloadStatus["UNCONFIRMED"] = 0] = "UNCONFIRMED";
    FileDownloadStatus[FileDownloadStatus["CONFIRMED"] = 1] = "CONFIRMED";
    FileDownloadStatus[FileDownloadStatus["TEMPORARY"] = 2] = "TEMPORARY";
    FileDownloadStatus[FileDownloadStatus["TRANSFERRING"] = 3] = "TRANSFERRING";
})(FileDownloadStatus || (exports.FileDownloadStatus = FileDownloadStatus = {}));
var SystemStatus;
(function (SystemStatus) {
    SystemStatus[SystemStatus["PASSIVE"] = 0] = "PASSIVE";
    SystemStatus[SystemStatus["ACTIVE"] = 1] = "ACTIVE";
})(SystemStatus || (exports.SystemStatus = SystemStatus = {}));
var AccountStatus;
(function (AccountStatus) {
    AccountStatus[AccountStatus["ACTIVE"] = 1] = "ACTIVE";
    AccountStatus[AccountStatus["PASSIVE"] = 2] = "PASSIVE";
    AccountStatus[AccountStatus["PENDING"] = 3] = "PENDING";
})(AccountStatus || (exports.AccountStatus = AccountStatus = {}));
var ResponseStatuses;
(function (ResponseStatuses) {
    ResponseStatuses[ResponseStatuses["UNEXPECTED"] = 1] = "UNEXPECTED";
    ResponseStatuses[ResponseStatuses["SUCCESS"] = 200] = "SUCCESS";
    ResponseStatuses[ResponseStatuses["CREATED"] = 201] = "CREATED";
    ResponseStatuses[ResponseStatuses["DELETED"] = 204] = "DELETED";
    ResponseStatuses[ResponseStatuses["UNAUTHENTICATED"] = 401] = "UNAUTHENTICATED";
    ResponseStatuses[ResponseStatuses["NOT_FOUND"] = 404] = "NOT_FOUND";
    ResponseStatuses[ResponseStatuses["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    ResponseStatuses[ResponseStatuses["TOO_LARGE"] = 413] = "TOO_LARGE";
    ResponseStatuses[ResponseStatuses["FORBIDDEN"] = 403] = "FORBIDDEN";
    ResponseStatuses[ResponseStatuses["CONFLICT"] = 409] = "CONFLICT";
    ResponseStatuses[ResponseStatuses["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    ResponseStatuses[ResponseStatuses["TOO_MANY"] = 429] = "TOO_MANY";
    ResponseStatuses[ResponseStatuses["SERVER_ERROR"] = 500] = "SERVER_ERROR";
    ResponseStatuses[ResponseStatuses["APPLICATION_UNAVAILABLE"] = 503] = "APPLICATION_UNAVAILABLE";
})(ResponseStatuses || (exports.ResponseStatuses = ResponseStatuses = {}));
var YesNoStatus;
(function (YesNoStatus) {
    YesNoStatus[YesNoStatus["NO"] = 0] = "NO";
    YesNoStatus[YesNoStatus["YES"] = 1] = "YES";
})(YesNoStatus || (exports.YesNoStatus = YesNoStatus = {}));
var OperatorType;
(function (OperatorType) {
    OperatorType[OperatorType["SUBTRACT"] = 1] = "SUBTRACT";
    OperatorType[OperatorType["ADD"] = 2] = "ADD";
})(OperatorType || (exports.OperatorType = OperatorType = {}));

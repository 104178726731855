"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignmentReducer = exports.useAssignmentReducer = exports.initialAssignmentState = exports.initialAssignmentFormValues = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const react_1 = require("react");
const types_2 = require("../types");
const parsers_1 = require("api/files/parsers");
const useCreateOrUpdateItems_1 = require("../../../documents/hr/assign-submissions/store/useCreateOrUpdateItems");
const initialEmployeePositionInfo = {
    ...useCreateOrUpdateItems_1.initialPositionInfo,
    positionName: null,
    isEdocUser: false,
    hasOtherCompensation: false,
    fixedSalaryAmount: null
};
exports.initialAssignmentFormValues = {
    assignId: null,
    // id: null,
    visualStatus: { name: 'აქტიური', color: 'success' },
    originalId: null,
    assignCategory: types_1.AssignCategory.INVITED,
    contractExtContinuationType: null,
    dismissalReasonShortName: null,
    dismissalReason: null,
    employmentContractType: null,
    recordNumber: null,
    recordDate: null,
    workEndDate: null,
    dismissalDate: null,
    title: null,
    effectiveDate: null,
    ...useCreateOrUpdateItems_1.initialPositionInfo,
    minStartDate: null,
    assignType: null,
    files: {
        contract: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.CONTRACT }],
        order: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.ORDER }],
        orderWithContract_Contract: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.CONTRACT_ORDER_CONTRACT }],
        orderWithContract_Order: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.CONTRACT_ORDER_ORDER }],
        contractExt: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.CONTRACT_EXT }],
        basicFunctionDutiesFiles: [{ ...parsers_1.initialFile, type: types_2.EmployeeAssignmentFileTypes.BASIC_FUNCTION_DUTIES }]
    },
    contractContractNumber: null,
    contractContractDate: null,
    contractContractType: null,
    contractExtContractNumber: null,
    contractExtContractDate: null,
    contractExtContractType: null,
    orderOrderNumber: null,
    orderOrderDate: null,
    orderOrderType: null,
    ocOrderNumber: null,
    ocContractNumber: null,
    ocOrderDate: null,
    ocOrderType: null,
    ocContractDate: null,
    ocContractType: null,
    positionInfos: [initialEmployeePositionInfo]
};
const initialAssignmentValues = {
    positions: []
};
exports.initialAssignmentState = {
    data: initialAssignmentValues,
    errors: null,
    isUpdating: false,
    isCreating: false,
    status: null,
    isFetching: true,
    isDeleting: false,
    isLoading: false,
    creatingWorkType: false,
    isCreatingCorrection: false
};
const useAssignmentReducer = () => {
    return (0, react_1.useReducer)(exports.assignmentReducer, exports.initialAssignmentState);
};
exports.useAssignmentReducer = useAssignmentReducer;
const assignmentReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEE_ASSIGNMENTS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_ASSIGNMENTS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    positions: action.payload.data || state.data.positions
                },
                errors: action.payload.errors,
                isFetching: false,
                isLoading: false
            };
        case 'SEND_EMPLOYEE_ASSIGNMENT_REQUEST':
            return {
                ...state,
                errors: null
            };
        case 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    positions: state.data.positions && state.data.positions.map(el => {
                        if (action.payload.data?.originalId === el.assignId) {
                            return {
                                ...action.payload.data
                            };
                        }
                        else {
                            return el;
                        }
                    })
                },
                errors: action.payload.errors
            };
        // case 'SEND_EMPLOYEE_ASSIGNMENT_REQUEST_CREATE_UPDATE':
        // 	return {
        // 		...state,
        // 		errors: null,
        // 		isFetching: true,
        // 		isLoading: true
        // 	}
        // case 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST_CREATE':
        // 	return {
        // 		...state,
        // 		data: {
        // 			...state.data,
        // 			positions: action.payload.data ? [...action.payload.data, ...state.data.positions as Array<EmployeePositionWithCorrection>] : state.data.positions
        // 		},
        // 		errors: state.errors,
        // 		isFetching: false,
        // 		isLoading: false
        // 	}
        // case 'DONE_EMPLOYEE_ASSIGNMENT_REQUEST_UPDATE':
        // 	return {
        // 		...state,
        // 		data: {
        // 			...state.data,
        // 			positions: state.data.positions?.map(el => {
        // 				if (action.payload.data?.find(item => item.originalId === item.assignId)) {
        // 					return action.payload.data?.find(item => item.assignId === el.assignId) || el
        // 				} else {
        // 					return el
        // 				}
        // 			}) || state.data.positions
        // 		},
        // 		errors: state.errors,
        // 		isFetching: false,
        // 		isLoading: false
        // 	}
        // case 'SEND_EMPLOYEE_ASSIGNMENT_CORRECTION_CREATE':
        // 	return {
        // 		...state,
        // 		errors: state.errors,
        // 		isCreatingCorrection: true
        // 	}
        // case 'DONE_EMPLOYEE_ASSIGNMENT_CORRECTION_CREATE':
        // 	return {
        // 		...state,
        // 		data: {
        // 			...state.data,
        // 			positions: state.data.positions && state.data.positions.map(el => {
        // 				if (action.payload.data?.find(item => item.originalId === el.assignId)) {
        // 					return {
        // 						...el,
        // 						correctedItems: action.payload.data ? el.correctedItems && el.correctedItems.concat(action.payload.data) : el.correctedItems
        // 					}
        // 				} else {
        // 					return el
        // 				}
        // 			})
        // 		},
        // 		isCreatingCorrection: false
        // 	}
        case 'SEND_EMPLOYEE_ASSIGNMENT_UPDATE':
            return {
                ...state,
                isCreating: true
            };
        case 'DONE_EMPLOYEE_ASSIGNMENT_UPDATE':
            return {
                ...state,
                data: {
                    positions: state.data.positions && state.data.positions.map(el => {
                        return el.assignId === action.payload.data?.assignId ? action.payload.data : el;
                    })
                },
                isCreating: false,
                errors: action.payload.errors
            };
        case 'SEND_EMPLOYEE_ASSIGNMENT_CREATE':
            return {
                ...state,
                isUpdating: true
            };
        case 'DONE_EMPLOYEE_ASSIGNMENT_CREATE':
            return {
                ...state,
                data: {
                    positions: state.data.positions && action.payload.data ? [action.payload.data, ...state.data.positions] : state.data.positions
                },
                isUpdating: false,
                errors: action.payload.errors
            };
        case 'SEND_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: true
            };
        case 'DONE_CREATE_WORK_TYPE_REQUEST':
            return {
                ...state,
                creatingWorkType: false
            };
        case 'SEND_ASSIGNMENT_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_ASSIGNMENT_DELETE_REQUEST':
            return {
                ...state,
                errors: action.payload,
                isDeleting: false
            };
    }
};
exports.assignmentReducer = assignmentReducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkTypesApi = exports.WarrantyTermsApi = exports.WarehousesApi = exports.VersionApi = exports.VacationsApi = exports.UsersApi = exports.SubmissionsApi = exports.SubmissionVerifiesApi = exports.RolesApi = exports.RegionsApi = exports.PositionTypeConfigApi = exports.PositionTypesApi = exports.AdministrativeOrdersApi = exports.LocationsApi = exports.DistrictsApi = exports.HolidaysApi = exports.NotificationsApi = exports.PermissionApi = exports.PurchasesSpecificationsApi = exports.PurchasesInventoryItemsApi = exports.PurchaseSubjectsApi = exports.PurchaseCategoriesApi = exports.PositionSubTypesApi = exports.ProjectsApi = exports.ProcurementsApi = exports.ProcurementTypesApi = exports.ProcurementStatisticsApi = exports.ProcurementCorrectionsApi = exports.LockApi = exports.InvoicesApi = exports.HumanResourcesSubmissionVerifiesApi = exports.HumanResourcesSubmissionsApi = exports.FundingSourcesApi = exports.FilesApi = exports.EmployeesApi = exports.DonorsApi = exports.DebuggingApi = exports.DimensionUnitsApi = exports.CurrenciesApi = exports.CpvCodesApi = exports.CountriesApi = exports.CorrectionApi = exports.ContractsApi = exports.ContractTypesApi = exports.ConditionalSubmissionsApi = exports.CompaniesApi = exports.BudgetSourcesApi = exports.BudgetArticlesApi = exports.Authenticate = exports.Auth = void 0;
exports.PurchaseReportApi = exports.GuaranteeArticlesApi = exports.MyStrctureApi = exports.AuthorizedAccountsApi = exports.StructuralUnitsUpgradedApi = exports.AuthHistoriesApi = exports.SentEmailsApi = exports.HeaderApi = exports.MyFilesApi = exports.NewsesApi = exports.ContractSigningApi = exports.RoomsApi = exports.AdministrativeBuildingsApi = exports.WorkDirectionApi = exports.EducationalInstitutionApi = exports.BankApi = exports.PositionTypeApi = exports.HRContractTypesApi = exports.WorkTypeApi = exports.LegalBasicsApi = exports.CommonApi = void 0;
const tslib_1 = require("tslib");
const AdministrativeBuildingsApi = tslib_1.__importStar(require("./references/addresses/administrativeBuildings"));
exports.AdministrativeBuildingsApi = AdministrativeBuildingsApi;
const AdministrativeOrdersApi = tslib_1.__importStar(require("./documents/orders/administrativeOrders"));
exports.AdministrativeOrdersApi = AdministrativeOrdersApi;
const Auth = tslib_1.__importStar(require("./auth"));
exports.Auth = Auth;
const AuthHistoriesApi = tslib_1.__importStar(require("./administer/authHistory"));
exports.AuthHistoriesApi = AuthHistoriesApi;
const Authenticate = tslib_1.__importStar(require("./auth"));
exports.Authenticate = Authenticate;
const AuthorizedAccountsApi = tslib_1.__importStar(require("./references/authorizedAccounts"));
exports.AuthorizedAccountsApi = AuthorizedAccountsApi;
const BankApi = tslib_1.__importStar(require("./references/banks"));
exports.BankApi = BankApi;
const BudgetArticlesApi = tslib_1.__importStar(require("./references/budgetArticle"));
exports.BudgetArticlesApi = BudgetArticlesApi;
const BudgetSourcesApi = tslib_1.__importStar(require("./references/funding/budget-sources"));
exports.BudgetSourcesApi = BudgetSourcesApi;
const CommonApi = tslib_1.__importStar(require("./common"));
exports.CommonApi = CommonApi;
const CompaniesApi = tslib_1.__importStar(require("./references/companies"));
exports.CompaniesApi = CompaniesApi;
const ConditionalSubmissionsApi = tslib_1.__importStar(require("./documents/purchases/conditional-submissions"));
exports.ConditionalSubmissionsApi = ConditionalSubmissionsApi;
const ContractSigningApi = tslib_1.__importStar(require("./documents/human-resources/contract-signing"));
exports.ContractSigningApi = ContractSigningApi;
const ContractTypesApi = tslib_1.__importStar(require("./references/contractTypes"));
exports.ContractTypesApi = ContractTypesApi;
const ContractsApi = tslib_1.__importStar(require("./documents/human-resources/hr-contracts"));
exports.ContractsApi = ContractsApi;
const CorrectionApi = tslib_1.__importStar(require("./documents/orders/correction"));
exports.CorrectionApi = CorrectionApi;
const CountriesApi = tslib_1.__importStar(require("./references/addresses/countries"));
exports.CountriesApi = CountriesApi;
const CpvCodesApi = tslib_1.__importStar(require("./references/cpvCodes"));
exports.CpvCodesApi = CpvCodesApi;
const CurrenciesApi = tslib_1.__importStar(require("./references/currencies"));
exports.CurrenciesApi = CurrenciesApi;
const DebuggingApi = tslib_1.__importStar(require("./debugging"));
exports.DebuggingApi = DebuggingApi;
const DimensionUnitsApi = tslib_1.__importStar(require("./references/dimensionUnits"));
exports.DimensionUnitsApi = DimensionUnitsApi;
const DistrictsApi = tslib_1.__importStar(require("./references/addresses/districts"));
exports.DistrictsApi = DistrictsApi;
const DonorsApi = tslib_1.__importStar(require("./references/funding/donors"));
exports.DonorsApi = DonorsApi;
const EducationalInstitutionApi = tslib_1.__importStar(require("./references/employees/educationalInstitution"));
exports.EducationalInstitutionApi = EducationalInstitutionApi;
const EmployeesApi = tslib_1.__importStar(require("./references/employees/"));
exports.EmployeesApi = EmployeesApi;
const FilesApi = tslib_1.__importStar(require("./files"));
exports.FilesApi = FilesApi;
const FundingSourcesApi = tslib_1.__importStar(require("./references/funding/funding-sources"));
exports.FundingSourcesApi = FundingSourcesApi;
const GuaranteeArticlesApi = tslib_1.__importStar(require("./references/guaranteeArticles"));
exports.GuaranteeArticlesApi = GuaranteeArticlesApi;
const HRContractTypesApi = tslib_1.__importStar(require("./references/humanResources/contractTypes"));
exports.HRContractTypesApi = HRContractTypesApi;
const HeaderApi = tslib_1.__importStar(require("./administer/news/index"));
exports.HeaderApi = HeaderApi;
const HolidaysApi = tslib_1.__importStar(require("./references/holidays"));
exports.HolidaysApi = HolidaysApi;
const HumanResourcesSubmissionVerifiesApi = tslib_1.__importStar(require("./documents/human-resources/assign-submission-verifies"));
exports.HumanResourcesSubmissionVerifiesApi = HumanResourcesSubmissionVerifiesApi;
const HumanResourcesSubmissionsApi = tslib_1.__importStar(require("./documents/human-resources/assign-submissions"));
exports.HumanResourcesSubmissionsApi = HumanResourcesSubmissionsApi;
const InvoicesApi = tslib_1.__importStar(require("./documents/purchases/invoices"));
exports.InvoicesApi = InvoicesApi;
const LegalBasicsApi = tslib_1.__importStar(require("./references/legalBasics"));
exports.LegalBasicsApi = LegalBasicsApi;
const LocationsApi = tslib_1.__importStar(require("./references/addresses/locations"));
exports.LocationsApi = LocationsApi;
const LockApi = tslib_1.__importStar(require("./lock"));
exports.LockApi = LockApi;
const MyFilesApi = tslib_1.__importStar(require("./my-space/myFiles"));
exports.MyFilesApi = MyFilesApi;
const MyStrctureApi = tslib_1.__importStar(require("./my-space/myStructure"));
exports.MyStrctureApi = MyStrctureApi;
const NewsesApi = tslib_1.__importStar(require("./administer/news"));
exports.NewsesApi = NewsesApi;
const NotificationsApi = tslib_1.__importStar(require("./notifications"));
exports.NotificationsApi = NotificationsApi;
const PermissionApi = tslib_1.__importStar(require("./administer/permission"));
exports.PermissionApi = PermissionApi;
const PositionSubTypesApi = tslib_1.__importStar(require("./references/humanResources/positionSubTypes"));
exports.PositionSubTypesApi = PositionSubTypesApi;
const PositionTypeApi = tslib_1.__importStar(require("./references/humanResources/majorPositionType"));
exports.PositionTypeApi = PositionTypeApi;
const PositionTypeConfigApi = tslib_1.__importStar(require("./references/humanResources/PositionTypeConfigs"));
exports.PositionTypeConfigApi = PositionTypeConfigApi;
const PositionTypesApi = tslib_1.__importStar(require("./references/humanResources/positionTypes"));
exports.PositionTypesApi = PositionTypesApi;
const ProcurementCorrectionsApi = tslib_1.__importStar(require("./documents/purchases/procurementCorrections"));
exports.ProcurementCorrectionsApi = ProcurementCorrectionsApi;
const ProcurementStatisticsApi = tslib_1.__importStar(require("./reports/purchases"));
exports.ProcurementStatisticsApi = ProcurementStatisticsApi;
const ProcurementTypesApi = tslib_1.__importStar(require("./references/procurementTypes"));
exports.ProcurementTypesApi = ProcurementTypesApi;
const ProcurementsApi = tslib_1.__importStar(require("./documents/purchases/procurements"));
exports.ProcurementsApi = ProcurementsApi;
const ProjectsApi = tslib_1.__importStar(require("./references/funding/projects"));
exports.ProjectsApi = ProjectsApi;
const PurchaseCategoriesApi = tslib_1.__importStar(require("./references/purchase-category"));
exports.PurchaseCategoriesApi = PurchaseCategoriesApi;
const PurchaseReportApi = tslib_1.__importStar(require("./documents/purchases/report"));
exports.PurchaseReportApi = PurchaseReportApi;
const PurchaseSubjectsApi = tslib_1.__importStar(require("./references/purchase-subject"));
exports.PurchaseSubjectsApi = PurchaseSubjectsApi;
const PurchasesInventoryItemsApi = tslib_1.__importStar(require("./references/inventoryItems"));
exports.PurchasesInventoryItemsApi = PurchasesInventoryItemsApi;
const PurchasesSpecificationsApi = tslib_1.__importStar(require("./references/purchase-subject-specifications"));
exports.PurchasesSpecificationsApi = PurchasesSpecificationsApi;
const RegionsApi = tslib_1.__importStar(require("./references/addresses/regions"));
exports.RegionsApi = RegionsApi;
const RolesApi = tslib_1.__importStar(require("./administer/roles"));
exports.RolesApi = RolesApi;
const RoomsApi = tslib_1.__importStar(require("./references/addresses/rooms"));
exports.RoomsApi = RoomsApi;
const SentEmailsApi = tslib_1.__importStar(require("./administer/sentEmails"));
exports.SentEmailsApi = SentEmailsApi;
const StructuralUnitsUpgradedApi = tslib_1.__importStar(require("./references/structuralUnitsUpgraded"));
exports.StructuralUnitsUpgradedApi = StructuralUnitsUpgradedApi;
const SubmissionVerifiesApi = tslib_1.__importStar(require("./documents/purchases/submissionVerifies"));
exports.SubmissionVerifiesApi = SubmissionVerifiesApi;
const SubmissionsApi = tslib_1.__importStar(require("./documents/purchases/submissions"));
exports.SubmissionsApi = SubmissionsApi;
const UsersApi = tslib_1.__importStar(require("./administer/users"));
exports.UsersApi = UsersApi;
const VacationsApi = tslib_1.__importStar(require("./documents/human-resources/vacations"));
exports.VacationsApi = VacationsApi;
const VersionApi = tslib_1.__importStar(require("./versions"));
exports.VersionApi = VersionApi;
const WarehousesApi = tslib_1.__importStar(require("./references/warehouses"));
exports.WarehousesApi = WarehousesApi;
const WarrantyTermsApi = tslib_1.__importStar(require("./references/warranty-terms"));
exports.WarrantyTermsApi = WarrantyTermsApi;
const WorkDirectionApi = tslib_1.__importStar(require("./references/humanResources/workDirection"));
exports.WorkDirectionApi = WorkDirectionApi;
const WorkTypeApi = tslib_1.__importStar(require("./references/humanResources/workTypes"));
exports.WorkTypeApi = WorkTypeApi;
const WorkTypesApi = tslib_1.__importStar(require("./documents/human-resources/work-types"));
exports.WorkTypesApi = WorkTypesApi;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformPurchasedItemsToFilterParams = exports.transformUrlToCreateFromProcurementParams = exports.transformInvoiceParamsToFilterParams = exports.getItemsParamsFromUrl = exports.transformItemFilterParamsToUrl = exports.transformFilterParamsToUrl = exports.getParamsFromUrl = exports.transformInvoiceSendStatusParams = exports.transformAdditionalFilesParams = exports.transformInvoiceItemsListParams = exports.transformInvoiceParams = void 0;
const types_1 = require("api/documents/purchases/invoices/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("src/modules/common/helper");
const queryString_1 = require("core/helpers/queryString");
const transformInvoiceParams = (values) => {
    const fileUids = values.files.map(el => ({ uid: el.id, type: el.type }));
    const invoiceFile = values.invoiceFile && {
        uid: values.invoiceFile.id,
        type: values.invoiceFile.type
    };
    if (invoiceFile)
        fileUids.push(invoiceFile);
    return {
        procurementId: values.procurement?.id,
        companyId: values.company?.id,
        fine: values.fine || null,
        fineAccount: values.fineAccount?.label || null,
        creationDate: (0, helper_1.nullableTransformParamsDate)(values.creationDate),
        receiptDate: (0, helper_1.nullableTransformParamsDate)(values.receiptDate),
        deliveryPlace: values.deliveryPlace,
        warehouseId: values.warehouse?.id,
        template: values.template,
        incomeType: values.incomeType && Number(values.incomeType.id) || undefined,
        type: values.invoiceType,
        fileUids,
        signatories: {
            users: values.signatories && values.signatories.map(el => {
                return {
                    type: el.type || types_1.InvoiceSignatoryType.PERFORMER,
                    userId: el.userId,
                    structuralUnitId: el.structuralUnitId
                };
            }) || [],
            companies: [
                {
                    companySignatoryId: values.companySignatory?.id || null,
                    companySignatoryPhoneId: values.companySignatoryPhone?.id || null
                }
            ]
        }
    };
};
exports.transformInvoiceParams = transformInvoiceParams;
const transformInvoiceItemsListParams = (values) => {
    return {
        items: values.map(item => {
            return {
                id: item.id,
                procurementItemId: item.procurementItemId,
                quantity: !item.unpredicted ? Number(item.quantity) : undefined,
                unpredicted: item.unpredicted || false,
                sumPrice: item.unpredicted ? item.price : undefined
            };
        })
    };
};
exports.transformInvoiceItemsListParams = transformInvoiceItemsListParams;
const transformAdditionalFilesParams = (values) => {
    return {
        fileUids: values.additionalFiles.map(el => ({ uid: el.id, type: el.type }))
    };
};
exports.transformAdditionalFilesParams = transformAdditionalFilesParams;
const transformInvoiceSendStatusParams = (values) => {
    return {
        sentToWarehouse: values.sentToWarehouse,
        sentToAccounting: values.sentToAccounting
    };
};
exports.transformInvoiceSendStatusParams = transformInvoiceSendStatusParams;
const getParamsFromUrl = (url) => {
    try {
        const values = (0, queryString_1.parse)(url);
        return {
            keyword: values.keyword,
            page: values.page,
            perPage: values.perPage,
            createdBy: values.createdById && values.createdByLabel ? { id: values.createdById, label: values.createdByLabel } : undefined,
            company: values.companyId && values.companyLabel ? { id: values.companyId, label: values.companyLabel } : undefined,
            hasFine: values.hasFine ? Number(values.hasFine) === apiGlobalTypes_1.YesNoStatus.YES : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformFilterParamsToUrl = (values) => {
    const { keyword, createdBy, company, hasFine, page, perPage } = values;
    return {
        keyword: keyword,
        createdById: createdBy && createdBy.id || undefined,
        createdByLabel: createdBy && createdBy.label || undefined,
        companyId: company && company.id || undefined,
        companyLabel: company && company.label || undefined,
        hasFine: hasFine ? 1 : undefined,
        page,
        perPage
    };
};
exports.transformFilterParamsToUrl = transformFilterParamsToUrl;
const transformItemFilterParamsToUrl = (params) => {
    return {
        page: params.itemPage,
        perPage: params.itemPerPage,
        filters: params.itemsKeyword ? { keyword: params.itemsKeyword } : undefined
    };
};
exports.transformItemFilterParamsToUrl = transformItemFilterParamsToUrl;
const getItemsParamsFromUrl = (url) => {
    const params = (0, queryString_1.parse)(url);
    return {
        itemPage: params.itemPage,
        itemsKeyword: params.itemsKeyword,
        itemPerPage: params.itemPerPage
    };
};
exports.getItemsParamsFromUrl = getItemsParamsFromUrl;
const transformInvoiceParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        createdBy: params.createdBy?.id || params.onlyMine,
        companyId: params.company?.id,
        keyword: params.keyword,
        hasFine: params.hasFine,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformInvoiceParamsToFilterParams = transformInvoiceParamsToFilterParams;
const transformUrlToCreateFromProcurementParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        procurementId: values.procurementId,
        procurementNumber: values.procurementNumber,
        contractTypeLabel: values.contractTypeLabel
    };
};
exports.transformUrlToCreateFromProcurementParams = transformUrlToCreateFromProcurementParams;
const transformPurchasedItemsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        submissionCreatorId: params.submissionCreator?.id,
        keyword: params.keyword,
        procurementId: params.procurementId,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(paginate).length === 0)
            return { filters };
        return { filters, ...paginate };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformPurchasedItemsToFilterParams = transformPurchasedItemsToFilterParams;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRoomsForSelect = exports.parseRoom = exports.parseRooms = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseRooms = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const Rooms = response.content;
    try {
        return {
            data: Rooms.data.map(el => {
                return {
                    id: el.id,
                    creator: el.relationships.creator.data.relationships.user.data.attributes.fullname
                        + ' - '
                        + el.relationships.creator.data.relationships.structuralUnit?.data.attributes.fullName,
                    roomNumber: el.attributes.number,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(Rooms.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRooms = parseRooms;
const parseRoom = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const room = response.content;
    try {
        return {
            data: {
                id: room.data.id,
                roomNumber: room.data.attributes.number,
                administrativeBuilding: {
                    id: room.data.relationships.administrativeBuilding.data.id,
                    label: room.data.relationships.administrativeBuilding.data.attributes.name
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseRoom = parseRoom;
const parseRoomsForSelect = (response) => {
    if (response.content === null)
        return [];
    const rooms = response.content;
    try {
        return rooms.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.number
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseRoomsForSelect = parseRoomsForSelect;

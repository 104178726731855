"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAuthorizedAccountFilterParamsToUrl = exports.transformAuthorizedAccountParamsToFilterParams = exports.transformAuthorizedAccountUrlToFilterParams = exports.getParamsFromUrl = void 0;
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters ? { authorizedForUserId: params.filters.authorizedForUserId } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformAuthorizedAccountUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        authorizedForAccountStructuralUnit: values.authorizedForAccountStructuralUnitId && values.authorizedForAccountStructuralUnitLabel ? { id: values.authorizedForAccountStructuralUnitId, label: values.authorizedForAccountStructuralUnitLabel } : undefined,
        authorizedAccountUser: values.authorizedAccountUserId && values.authorizedAccountUserLabel ? { id: values.authorizedAccountUserId, label: values.authorizedAccountUserLabel } : undefined,
        authorizedAccountStatus: values.authorizedAccountStatusId && values.authorizedAccountStatusLabel ? { id: values.authorizedAccountStatusId, label: values.authorizedAccountStatusLabel } : undefined,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformAuthorizedAccountUrlToFilterParams = transformAuthorizedAccountUrlToFilterParams;
const transformAuthorizedAccountParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        page: params.page,
        perPage: params.perPage,
        authorizedForAccountStructuralUnitId: params.authorizedForAccountStructuralUnit?.id,
        authorizedAccountUserId: params.authorizedAccountUser?.id,
        authorizedAccountStatus: params.authorizedAccountStatus?.id
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformAuthorizedAccountParamsToFilterParams = transformAuthorizedAccountParamsToFilterParams;
const transformAuthorizedAccountFilterParamsToUrl = (values) => {
    return {
        page: values.page,
        perPage: values.perPage,
        authorizedForAccountStructuralUnitId: values.authorizedForAccountStructuralUnit && values.authorizedForAccountStructuralUnit.id,
        authorizedForAccountStructuralUnitLabel: values.authorizedForAccountStructuralUnit && values.authorizedForAccountStructuralUnit.label,
        authorizedAccountUserId: values.authorizedAccountUser && values.authorizedAccountUser.id,
        authorizedAccountUserLabel: values.authorizedAccountUser && values.authorizedAccountUser.label,
        authorizedAccountStatusId: values.authorizedAccountStatus && values.authorizedAccountStatus.id,
        authorizedAccountStatusLabel: values.authorizedAccountStatus && values.authorizedAccountStatus.label
    };
};
exports.transformAuthorizedAccountFilterParamsToUrl = transformAuthorizedAccountFilterParamsToUrl;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const employees_1 = require("../store/employees");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("core/helpers");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useEmployees = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, employees_1.useEmployeesReducer)();
    const [deletedEmployeeId, setDeletedEmployeeId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [employeeId, setEmployeeId] = (0, react_1.useState)(null);
    // const [selectedItemIds, setSelectedItemIds] = useState<Array<ID>>([])
    // const [showNotifyModal, setShowNotifyModal] = useState<NotifyModalType | null>(null)
    const isMounted = (0, react_1.useRef)(false);
    const filter = (name, value, filterValues) => {
        getEmployees({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getEmployees({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        getEmployees({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedEmployeeId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getEmployees);
    };
    const deleteEmployee = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedEmployeeId || !confirm)
            return setDeletedEmployeeId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const employees = await index_1.EmployeesApi.deleteEmployee(deletedEmployeeId);
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: employees.errors } });
        if (employees.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getEmployees(params).then();
        setDeletedEmployeeId(null);
    };
    const getEmployees = async (values) => {
        dispatch({ type: 'SEND_EMPLOYEES_REQUEST' });
        const filterParams = (0, helper_1.transformEmployeeParamsToFilterParams)(values, props.accountId);
        const employees = await index_1.EmployeesApi.getEmployees(filterParams);
        if (employees.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helper_1.transformEmployeeFilterParamsToUrl)(values, props.accountId)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_EMPLOYEES_REQUEST', payload: employees });
            navigate('/references/employees' + (0, queryString_1.stringify)((0, helper_1.transformEmployeeFilterParamsToUrl)(values, props.accountId)), { replace: true });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helper_1.transformUrlToEmployeeFilterParams)(location.search);
        if ((0, helpers_1.isAnyFilterValue)(params))
            setShowFilter(true);
        const employeeListId = (0, queryString_1.parse)(location.search).employeeId;
        getEmployees(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setEmployeeId(employeeListId);
    };
    // const notifyPendingContractFiles = async (message: string): Promise<GlobalResponse> => {
    //   setShowNotifyModal(null)
    //   const params = transformNotifyPendingContractFilesParams(showNotifyModal, selectedItemIds, message)
    //   dispatch({ type: 'SEND_NOTIFY_PENDING_CONTRACT_FILES' })
    //   const response = await EmployeesApi.notifyPendingContractFiles(params)
    //   dispatch({ type: 'DONE_NOTIFY_PENDING_CONTRACT_FILES' })
    //   if(response.status === ResponseStatuses.SUCCESS) setSelectedItemIds([])
    //   return response
    // }
    //
    // const notifyAllPendingContractFiles = async (message: string): Promise<GlobalResponse> => {
    //   setShowNotifyModal(null)
    //   const params = transformNotifyPendingContractFilesParams(showNotifyModal, selectedItemIds, message)
    //   dispatch({ type: 'SEND_NOTIFY_ALL_PENDING_CONTRACT_FILES' })
    //   const response = await EmployeesApi.notifyPendingContractFiles(params)
    //   dispatch({ type: 'DONE_NOTIFY_ALL_PENDING_CONTRACT_FILES' })
    //   if(response.status === ResponseStatuses.SUCCESS) {
    //     const params = transformUrlToEmployeeFilterParams(history.location.search) as EmployeesParams
    //     getEmployees(params).then()
    //   }
    //   return response
    // }
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, [props.accountId]);
    return {
        state,
        navigatePagination,
        selectPerPage,
        deleteEmployee,
        deleteAlert,
        deletedEmployeeId,
        showDeleteAlert,
        debouncedSearch,
        getEmployees,
        filter,
        linkParams,
        showFilter,
        setShowFilter,
        employeeId
        // notifyPendingContractFiles,
        // selectedItemIds,
        // setSelectedItemIds,
        // setShowNotifyModal,
        // showNotifyModal,
        // notifyAllPendingContractFiles
    };
};
exports.default = useEmployees;

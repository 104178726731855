"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// import { PurchaseReportApi } from 'src/api'
const react_1 = require("react");
const api_1 = require("src/api");
const useReportFields = () => {
    const [state, setState] = (0, react_1.useState)({ data: null, errors: null, status: null });
    const [fetching, setFetching] = (0, react_1.useState)(false);
    const [chosenFields, setChosenFields] = (0, react_1.useState)([]);
    const [exportState, setExportState] = (0, react_1.useState)(null);
    const getReportFields = async () => {
        setFetching(true);
        const response = await api_1.PurchaseReportApi.getReportFields();
        setState(response);
        setFetching(false);
    };
    const closeErrorMessage = () => {
        setState(prevState => ({ ...prevState, errors: null }));
    };
    const closeExportError = () => {
        setExportState(prevState => ({ ...prevState, errors: null }));
    };
    (0, react_1.useEffect)(() => { getReportFields().then(); }, []);
    return {
        state,
        fetching,
        chosenFields,
        setChosenFields,
        closeErrorMessage,
        closeExportError,
        exportState,
        setExportState
    };
};
exports.default = useReportFields;

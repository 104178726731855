"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addAdditionalFiles = exports.deleteProcurementCorrection = exports.updateProcurementCorrection = exports.getLetterOrSubmissionCorrectionForm = exports.updateLetterOrSubmissionForm = exports.creteProcurementCorrection = exports.getProcurementCorrection = exports.getProcurementCorrections = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const parsers_1 = require("api/documents/purchases/procurementCorrections/parsers");
const parsers_2 = require("api/documents/purchases/procurements/parsers");
const getProcurementCorrections = async (procurementId) => {
    const response = await Api.get(`documents/purchases/procurements/${procurementId}/corrections`);
    return (0, parsers_1.parseProcurementCorrections)(response);
};
exports.getProcurementCorrections = getProcurementCorrections;
const getProcurementCorrection = async (id, original) => {
    const response = await Api.get('documents/purchases/procurements/' + id);
    return (0, parsers_2.parseProcurement)(response, original);
};
exports.getProcurementCorrection = getProcurementCorrection;
const creteProcurementCorrection = async (id, params) => {
    // id is last procurement signed procurement/corrected procurement
    const response = await Api.post(`documents/purchases/procurements/correction/${id}`, params);
    return (0, parsers_1.parseCreateProcurementCorrection)(response);
};
exports.creteProcurementCorrection = creteProcurementCorrection;
const updateLetterOrSubmissionForm = async (id, params) => {
    const response = await Api.patch(`documents/purchases/procurements/correction/${id}`, params);
    return (0, parsers_1.parseUpdateLetterOrSubmission)(response);
};
exports.updateLetterOrSubmissionForm = updateLetterOrSubmissionForm;
const getLetterOrSubmissionCorrectionForm = async (id) => {
    const response = await Api.get(`documents/purchases/procurements/correction/${id}`);
    return (0, parsers_1.parseLetterOrSubmission)(response);
};
exports.getLetterOrSubmissionCorrectionForm = getLetterOrSubmissionCorrectionForm;
const updateProcurementCorrection = async (id, params) => {
    // id is last procurement signed procurement/corrected procurement
    const response = await Api.patch(`documents/purchases/procurements/${id}`, params);
    return (0, parsers_1.parseUpdateProcurementCorrection)(response);
};
exports.updateProcurementCorrection = updateProcurementCorrection;
const deleteProcurementCorrection = async (id) => {
    return await Api.deleteItem(`documents/purchases/procurements/${id}`);
};
exports.deleteProcurementCorrection = deleteProcurementCorrection;
const addAdditionalFiles = async (id, params, original) => {
    const response = await Api.patch(`documents/purchases/procurements/${id}/accounting-file-upload`, params);
    return (0, parsers_2.parseProcurement)(response, original);
};
exports.addAdditionalFiles = addAdditionalFiles;

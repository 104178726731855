"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProjects = void 0;
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const projects_1 = require("../stores/projects");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("core/helpers");
const queryString_1 = require("core/helpers/queryString");
const helper_1 = require("../helper");
const react_router_dom_1 = require("react-router-dom");
const useProjects = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, projects_1.useProjectsReducer)();
    const [toDeleteId, setToDeleteId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [userOptions, setUserOptions] = (0, react_1.useState)([]);
    const [isLoadingOptions, isSetLoadingOptions] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [showSelectAll, setShowSelectAll] = (0, react_1.useState)(false);
    const [showAllSubstitute, setShowAllSubstitute] = (0, react_1.useState)(false);
    const [projectId, setProjectId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const navigatePagination = (page, values) => {
        getProjects({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: { ...values, perPage } });
        getProjects({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setToDeleteId(id);
    };
    const getUserOptions = async () => {
        isSetLoadingOptions(true);
        const userData = await index_1.UsersApi.getUsers({ filters: { permission: 'user_unit_permissions.budgeting_dep' } });
        if (userData.data !== null) {
            setUserOptions(userData.data.map(user => {
                return {
                    id: user.id,
                    label: user.fullName
                };
            }));
        }
        isSetLoadingOptions(false);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getProjects);
    };
    const deleteProject = async (confirm) => {
        setShowDeleteAlert(false);
        if (!toDeleteId || !confirm)
            return setToDeleteId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const projects = await index_1.ProjectsApi.deleteProject(toDeleteId);
        const params = (0, queryString_1.parse)(location.search);
        if (projects.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getProjects(params).then();
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: projects.errors } });
        setToDeleteId(null);
    };
    const getProjects = async (values) => {
        dispatch({ type: 'SEND_PROJECTS_REQUEST' });
        const filterParams = (0, helper_1.transformProjectParamsToFilterParams)(values);
        const projects = await index_1.ProjectsApi.getProjects(filterParams);
        if (projects.data !== null) {
            setLinkParams((0, queryString_1.stringify)((0, helper_1.transformProjectFilterParamsToUrl)(values)));
            setShowSelectAll(projects.data.some(project => project.hasPerformers));
        }
        if (isMounted?.current) {
            dispatch({ type: 'DONE_PROJECTS_REQUEST', payload: projects });
            navigate('/references/funding/projects' + (0, queryString_1.stringify)((0, helper_1.transformProjectFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const updateProjectPerformers = async (values) => {
        const params = (0, helper_1.transformProjectPerformersParams)(values, state.data);
        if (params !== null) {
            dispatch({ type: 'SEND_PROJECT_PERFORMERS_UPDATE' });
            const projects = await index_1.ProjectsApi.updateProjectPerformers(params);
            dispatch({ type: 'DONE_PROJECT_PERFORMERS_UPDATE', payload: projects });
            return projects;
        }
        return {
            data: null,
            status: apiGlobalTypes_1.ResponseStatuses.FORBIDDEN,
            errors: [{ name: 'same user', message: 'გასანახლებელი და არსებული მონაცემები ერთი და იგივეა' }]
        };
    };
    const filter = (name, value, filterValues) => {
        getProjects({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const initializeFilterForm = async () => {
        const params = (0, helper_1.transformUrlToProjectFilterParams)(location.search);
        if ((0, helpers_1.isAnyFilterValue)(params))
            setShowFilter(true);
        const projectListId = (0, queryString_1.parse)(location.search).projectId;
        // getProjects(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }))
        await getProjects(params);
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params });
        setProjectId(projectListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        filter,
        deleteProject,
        deleteAlert,
        debouncedSearch,
        toDeleteId,
        showDeleteAlert,
        showFilter,
        setShowFilter,
        getProjects,
        userOptions,
        isLoadingOptions,
        updateProjectPerformers,
        linkParams,
        getUserOptions,
        showSelectAll,
        setShowSelectAll,
        setShowAllSubstitute,
        showAllSubstitute,
        projectId
    };
};
exports.useProjects = useProjects;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createAuthority_1 = require("src/modules/references/structuralUnitsUpgraded/store/createAuthority");
const api_1 = require("src/api");
const helpers_1 = require("src/modules/references/structuralUnitsUpgraded/helpers");
const useCreateAuthority = (props) => {
    const [state, dispatch] = (0, createAuthority_1.useCreateAuthorityReducer)();
    const createAuthority = async (values) => {
        const params = (0, helpers_1.transformCreateAuthority)(values, props.unitId);
        dispatch({ type: 'SEND_CREATE_AUTHORITY' });
        const authority = await api_1.StructuralUnitsUpgradedApi.createAuthority(params);
        dispatch({ type: 'DONE_CREATE_AUTHORITY', payload: authority });
        return authority;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    return { state, createAuthority, closeErrorMessage };
};
exports.default = useCreateAuthority;

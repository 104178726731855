"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformFilterFormValuesToUrl = exports.transformFilterFormValuesToFilterParams = exports.transformUrlToFilterParams = void 0;
const queryString_1 = require("core/helpers/queryString");
const parsers_1 = require("api/parsers/parsers");
const transformUrlToFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        contractDateStart: values.contractDateStart,
        contractDateEnd: values.contractDateEnd,
        contractValidityDateStart: values.contractValidityDateStart,
        contractValidityDateEnd: values.contractValidityDateEnd,
        advanceGuaranteeValidityDateStart: values.advanceGuaranteeValidityDateStart,
        advanceGuaranteeValidityDateEnd: values.advanceGuaranteeValidityDateEnd,
        deliveryDateStart: values.deliveryDateStart,
        deliveryDateEnd: values.deliveryDateEnd,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToFilterParams = transformUrlToFilterParams;
const transformFilterFormValuesToFilterParams = (values) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: values.keyword,
        contractDateStart: values.contractDateStart ? (0, parsers_1.transformDateParams)(values.contractDateStart) : undefined,
        contractDateEnd: values.contractDateEnd ? (0, parsers_1.transformDateParams)(values.contractDateEnd) : undefined,
        contractValidityDateStart: values.contractValidityDateStart ? (0, parsers_1.transformDateParams)(values.contractValidityDateStart) : undefined,
        contractValidityDateEnd: values.contractValidityDateEnd ? (0, parsers_1.transformDateParams)(values.contractValidityDateEnd) : undefined,
        advanceGuaranteeValidityDateStart: values.advanceGuaranteeValidityDateStart ? (0, parsers_1.transformDateParams)(values.advanceGuaranteeValidityDateStart) : undefined,
        advanceGuaranteeValidityDateEnd: values.advanceGuaranteeValidityDateEnd ? (0, parsers_1.transformDateParams)(values.advanceGuaranteeValidityDateEnd) : undefined,
        deliveryDateStart: values.deliveryDateStart ? (0, parsers_1.transformDateParams)(values.deliveryDateStart) : undefined,
        deliveryDateEnd: values.deliveryDateEnd ? (0, parsers_1.transformDateParams)(values.deliveryDateEnd) : undefined,
        page: values.page,
        perPage: values.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformFilterFormValuesToFilterParams = transformFilterFormValuesToFilterParams;
const transformFilterFormValuesToUrl = (values) => {
    return {
        keyword: values.keyword,
        contractDateStart: values.contractDateStart ? (0, parsers_1.transformDateParams)(values.contractDateStart) : undefined,
        contractDateEnd: values.contractDateEnd ? (0, parsers_1.transformDateParams)(values.contractDateEnd) : undefined,
        contractValidityDateStart: values.contractValidityDateStart ? (0, parsers_1.transformDateParams)(values.contractValidityDateStart) : undefined,
        contractValidityDateEnd: values.contractValidityDateEnd ? (0, parsers_1.transformDateParams)(values.contractValidityDateEnd) : undefined,
        advanceGuaranteeValidityDateStart: values.advanceGuaranteeValidityDateStart ? (0, parsers_1.transformDateParams)(values.advanceGuaranteeValidityDateStart) : undefined,
        advanceGuaranteeValidityDateEnd: values.advanceGuaranteeValidityDateEnd ? (0, parsers_1.transformDateParams)(values.advanceGuaranteeValidityDateEnd) : undefined,
        deliveryDateStart: values.deliveryDateStart ? (0, parsers_1.transformDateParams)(values.deliveryDateStart) : undefined,
        deliveryDateEnd: values.deliveryDateEnd ? (0, parsers_1.transformDateParams)(values.deliveryDateEnd) : undefined,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformFilterFormValuesToUrl = transformFilterFormValuesToUrl;

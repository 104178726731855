"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.structuralUnitPositionsAutocompleteReducer = exports.useStructuralUnitPositionsAutocompleteReducer = exports.initialStructuralUnitPositionsAutocompleteState = void 0;
const react_1 = require("react");
exports.initialStructuralUnitPositionsAutocompleteState = {
    data: [],
    errors: null,
    isFetching: false,
    isCreatingStructuralUnitPosition: false
};
const useStructuralUnitPositionsAutocompleteReducer = () => {
    return (0, react_1.useReducer)(exports.structuralUnitPositionsAutocompleteReducer, exports.initialStructuralUnitPositionsAutocompleteState);
};
exports.useStructuralUnitPositionsAutocompleteReducer = useStructuralUnitPositionsAutocompleteReducer;
const structuralUnitPositionsAutocompleteReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_STRUCTURAL_UNITS_POSITION_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_STRUCTURAL_UNITS_POSITION_AUTOCOMPLETE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isFetching: false,
                errors: action.payload.errors
            };
        case 'RESET_DATA':
            return {
                ...state,
                data: []
            };
        case 'SEND_CREATE_STRUCTURAL_UNIT_POSITION_REQUEST':
            return {
                ...state,
                isCreatingStructuralUnitPosition: true
            };
        case 'DONE_CREATE_STRUCTURAL_UNIT_POSITION_REQUEST':
            return {
                ...state,
                isCreatingStructuralUnitPosition: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.structuralUnitPositionsAutocompleteReducer = structuralUnitPositionsAutocompleteReducer;

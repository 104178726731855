"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.purchaseReportReducer = exports.usePurchaseReportReducer = exports.initialPurchaseReportState = void 0;
const react_1 = require("react");
const types_1 = require("api/documents/purchases/procurements/types");
const initialPurchaseReportValue = {
    con: '',
    status: '',
    contractGuaranteeValidityPeriod: null,
    cmr: null,
    templateType: types_1.TemplateTypes.SIMPLIFIED,
    advanceGuaranteeValidityPeriod: null,
    budgetSources: '',
    companyDetails: '',
    cpvCodes: '',
    cpvCodesNames: '',
    creatorName: '',
    sumPrice: 0,
    invoicesSumPriceForPurchase: null,
    crmSpaConNumbers: '',
    deliveredToAccounting: '',
    fundingSources: '',
    invoiceResource: null,
    invoicesFinesSumPrice: null,
    invoicesSumPriceForAccounting: null,
    itemsInfo: '',
    payment: null,
    legalBasis: null,
    paymentResource: null,
    remark: '',
    spa: null,
    projects: '',
    submissionAuthors: '',
    submissionNumbers: '',
    subStatus: '',
    sumPriceInForeignCurrency: 0,
    tenderLink: ''
};
exports.initialPurchaseReportState = {
    data: initialPurchaseReportValue,
    errors: null,
    status: null,
    isFetching: false
};
const usePurchaseReportReducer = () => {
    return (0, react_1.useReducer)(exports.purchaseReportReducer, exports.initialPurchaseReportState);
};
exports.usePurchaseReportReducer = usePurchaseReportReducer;
const purchaseReportReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PURCHASE_REPORT_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_PURCHASE_REPORT_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.purchaseReportReducer = purchaseReportReducer;

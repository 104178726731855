"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAssignSubmissionVerifyItemsParams = exports.getAssignSubmissionVerifyItemsParamsFromUrl = exports.transformAssignSubmissionItemsFilterParams = exports.handleDeleteItem = exports.transformAssignSubmissionVerifyItemsListParams = exports.transformAssignSubmissionVerifyFilterParamsToUrl = exports.transformAssignSubmissionVerifyParamsToFilterParams = exports.transformUrlToAssignSubmissionVerifyFilterParams = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_2 = require("api/references/structuralUnitsUpgraded/types");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../../assign-submissions/helpers");
const parsers_1 = require("api/parsers/parsers");
const transformUrlToAssignSubmissionVerifyFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToAssignSubmissionVerifyFilterParams = transformUrlToAssignSubmissionVerifyFilterParams;
const transformAssignSubmissionVerifyParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformAssignSubmissionVerifyParamsToFilterParams = transformAssignSubmissionVerifyParamsToFilterParams;
const transformAssignSubmissionVerifyFilterParamsToUrl = (values) => {
    const { page, perPage, keyword } = values;
    return {
        page,
        perPage,
        keyword
    };
};
exports.transformAssignSubmissionVerifyFilterParamsToUrl = transformAssignSubmissionVerifyFilterParamsToUrl;
const transformAssignSubmissionVerifyItemsListParams = (values) => {
    return { items: values.map(el => transformAssignSubmissionVerifyItemListParams(el)) };
};
exports.transformAssignSubmissionVerifyItemsListParams = transformAssignSubmissionVerifyItemsListParams;
const transformAssignSubmissionVerifyItemListParams = (values) => {
    const cv = values.files.cv.filter(el => el.id);
    const bankingRequisites = values.files.bankingRequisites.filter(el => el.id);
    const taxCertificate = values.files.taxCertificate.filter(el => el.id);
    const diploma = values.files.diploma.filter(el => el.id);
    const conviction = values.files.conviction.filter(el => el.id);
    const other = values.files.other.filter(el => el.id);
    const passport = values.files.passport.filter(el => el.id);
    const files = [
        ...passport, ...cv, ...bankingRequisites,
        ...taxCertificate, ...diploma, ...conviction, ...other
    ];
    return {
        id: values.id,
        submissionItemId: values.assignSubmissionItemId || undefined,
        businessEmail: values.workEmail,
        certificateOfResidenceNumber: values.residenceCertificateNumber,
        certificateOfResidenceFrom: values.residencyValidityDate && (0, parsers_1.transformDateParams)(values.residencyValidityDate.split(' — ')[0]),
        certificateOfResidenceTo: values.residencyValidityDate && (0, parsers_1.transformDateParams)(values.residencyValidityDate.split(' — ')[1]),
        countryId: values.country && values.country.id,
        // nonResidentFrom: values.residenceCertificateDate && transformDate((values.residenceCertificateDate.split(' — ')[0] as string)),
        // nonResidentTo: values.residenceCertificateDate && transformDate((values.residenceCertificateDate.split(' — ')[1] as string)),
        resident: values.citizen === types_1.Citizen.FOREIGN ? values.resident : null,
        taxReliefAmount: values.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES && values.useOfTaxRelief ? Number(values.taxReliefAmount) : null,
        taxReliefBenefit: values.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES,
        taxReliefId: values.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES && values.taxReliefId ? values.taxReliefId : null,
        useOfTaxRelief: values.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES && values.useOfTaxRelief,
        validityOfIdCardFrom: values.idCardValidityDate && (0, parsers_1.transformDateParams)(values.idCardValidityDate.split(' — ')[0]),
        validityOfIdCardTo: values.idCardValidityDate && (0, parsers_1.transformDateParams)(values.idCardValidityDate.split(' — ')[1]),
        validityOfPassportFrom: values.passportValidityDate && (0, parsers_1.transformDateParams)(values.passportValidityDate.split(' — ')[0]),
        validityOfPassportTo: values.passportValidityDate && (0, parsers_1.transformDateParams)(values.passportValidityDate.split(' — ')[1]),
        foreignCitizen: values.citizen === types_1.Citizen.FOREIGN,
        personalNumber: values.personalNumber && values.personalNumber.length > 0 ? values.personalNumber : null,
        passportNumber: values.passportNumber && values.passportNumber.length > 0 ? values.passportNumber : null,
        firstname: values.firstname,
        lastname: values.lastname,
        phoneNumber: values.phoneNumber,
        email: values.email,
        legalAddress: values.legalAddress,
        actualAddress: values.isDifferentAddress ? values.physicalAddress : values.legalAddress,
        fileUids: files.map(file => ({ uid: file.id, type: file.type })),
        isEdocUser: values.isEdocUser,
        openBankAccount: values.openBankAccount,
        openCorporateEmail: values.openCorporateEmail,
        positionInfo: values.positionInfos.map(position => transformAssignSubmissionVerifyPosition(position))
    };
};
const transformAssignSubmissionVerifyPosition = (position) => {
    const assignPosition = {
        ...(0, helpers_1.transformAssignSubmissionPosition)(position, null),
        workDirection: undefined,
        position: position.structuralUnit?.type === types_2.StructuralUnitType.STRUCTURAL_UNIT ? position.position : null
    };
    return {
        ...assignPosition,
        positionTypeId: position.positionType.id,
        assignType: Number(position.assignType.id),
        employmentContractType: position.employmentContractType,
        contractContinuationType: position.contractContinuationType,
        workDirectionId: position.selectWorkDirection?.id || null
    };
};
const handleDeleteItem = (index, items) => {
    const newData = [...items];
    newData.splice(index, 1);
    return newData;
};
exports.handleDeleteItem = handleDeleteItem;
const transformAssignSubmissionItemsFilterParams = (values) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformAssignSubmissionItemsFilterParams = transformAssignSubmissionItemsFilterParams;
const getAssignSubmissionVerifyItemsParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            itemPage: params.itemPage,
            itemPerPage: params.itemPerPage,
            itemKeyword: params.itemKeyword ?? undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getAssignSubmissionVerifyItemsParamsFromUrl = getAssignSubmissionVerifyItemsParamsFromUrl;
const transformAssignSubmissionVerifyItemsParams = (params) => {
    return {
        page: params.itemPage,
        perPage: params.itemPerPage,
        filters: { keyword: params.itemKeyword ?? undefined }
    };
};
exports.transformAssignSubmissionVerifyItemsParams = transformAssignSubmissionVerifyItemsParams;

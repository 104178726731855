"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const additionalFiles_1 = require("src/modules/documents/purchases/invoices/store/additionalFiles");
const api_1 = require("src/api");
const helper_1 = require("src/modules/documents/purchases/invoices/helper");
const react_1 = require("react");
const useAdditionalFiles = (props) => {
    const [state, dispatch] = (0, additionalFiles_1.useAdditionalFilesReducer)();
    const getAdditionalFiles = async (id) => {
        dispatch({ type: 'SEND_ADDITIONAL_FILES_REQUEST' });
        const response = await api_1.InvoicesApi.getAdditionalFiles(id);
        dispatch({ type: 'DONE_ADDITIONAL_FILES_REQUEST', payload: response });
    };
    const addAdditionalFiles = async (id, values) => {
        const params = (0, helper_1.transformAdditionalFilesParams)(values);
        dispatch({ type: 'SEND_ADD_ADDITIONAL_FILES' });
        const response = await api_1.InvoicesApi.addAdditionalFiles(id, params);
        dispatch({ type: 'DONE_ADD_ADDITIONAL_FILES', payload: response });
        return response;
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getAdditionalFiles(props.id).then(); }, [props.id]);
    return {
        state,
        getAdditionalFiles,
        addAdditionalFiles
    };
};
exports.default = useAdditionalFiles;

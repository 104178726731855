"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalFilesReducer = exports.useAdditionalFilesReducer = exports.initialAdditionalFilesState = exports.initialAdditionalFiles = void 0;
const react_1 = require("react");
exports.initialAdditionalFiles = {
    additionalFiles: []
};
exports.initialAdditionalFilesState = {
    data: exports.initialAdditionalFiles,
    errors: null,
    status: null,
    isLoading: false,
    isUpdating: false
};
const useAdditionalFilesReducer = () => {
    return (0, react_1.useReducer)(exports.additionalFilesReducer, exports.initialAdditionalFilesState);
};
exports.useAdditionalFilesReducer = useAdditionalFilesReducer;
const additionalFilesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_ADDITIONAL_FILES_REQUEST':
            return {
                ...state,
                isLoading: true,
                errors: null
            };
        case 'DONE_ADDITIONAL_FILES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isLoading: false
            };
        case 'SEND_ADD_ADDITIONAL_FILES':
            return {
                ...state,
                isLoading: true,
                errors: null,
                isUpdating: true
            };
        case 'DONE_ADD_ADDITIONAL_FILES':
            return {
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isLoading: false,
                isUpdating: false
            };
    }
};
exports.additionalFilesReducer = additionalFilesReducer;

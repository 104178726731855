"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dismissalReasonOptions = exports.parseMajorContractsForSelect = exports.parseDismissal = exports.parseDismissals = void 0;
const types_1 = require("api/references/employees/types");
const types_2 = require("api/references/structuralUnitsUpgraded/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseDismissals = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const dismissals = response.content;
    try {
        return {
            data: dismissals.data.map(dismissal => {
                return {
                    id: dismissal.id,
                    dismissalDate: (0, parsers_1.nullableTransformDate)(dismissal.attributes.dismissalDate),
                    assign: {
                        id: dismissal.relationships.contract.data.id,
                        label: (dismissal.relationships.contract.data.relationships.positionInfo.data[0]
                            .relationships.structuralUnit.data.attributes.type === types_2.StructuralUnitType.STRUCTURAL_UNIT
                            ? dismissal.relationships.contract.data.relationships.positionInfo.data[0]
                                .relationships.structuralUnit.data.attributes.fullName
                            : dismissal.relationships.contract.data.relationships.positionInfo.data[0]
                                .relationships.structuralUnit.data.relationships.parentUnit?.data.attributes.fullName)
                            + ' -> ' + dismissal.relationships.contract.data.relationships.positionInfo.data[0].attributes.position
                    },
                    number: dismissal.relationships.contract.data.attributes.contractNumber ?
                        'შრომითი ხელშეკრულების N' + ': ' + dismissal.relationships.contract.data.attributes.contractNumber
                        : 'ბრძანების N' + ': ' + dismissal.relationships.contract.data.attributes.orderNumber,
                    positionName: dismissal.relationships.contract.data.relationships.positionInfo.data[0].attributes.position,
                    date: (0, parsers_1.nullableTransformDate)(dismissal.attributes.date),
                    dismissalReason: exports.dismissalReasonOptions.find(el => el.id === String(dismissal.attributes.reason)),
                    documentNumber: dismissal.attributes.documentNumber,
                    title: dismissal.attributes.title
                };
            }),
            meta: (0, parsers_1.transformPagination)(dismissals.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDismissals = parseDismissals;
const parseDismissal = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const dismissal = response.content;
    try {
        return {
            data: {
                id: dismissal.data.id,
                dismissalDate: (0, parsers_1.nullableTransformDate)(dismissal.data.attributes.dismissalDate),
                assign: {
                    id: dismissal.data.relationships.contract.data.id,
                    label: (dismissal.data.relationships.contract.data.relationships.positionInfo.data[0]
                        .relationships.structuralUnit.data.attributes.type === types_2.StructuralUnitType.STRUCTURAL_UNIT
                        ? dismissal.data.relationships.contract.data.relationships.positionInfo.data[0]
                            .relationships.structuralUnit.data.attributes.fullName
                        : dismissal.data.relationships.contract.data.relationships.positionInfo.data[0]
                            .relationships.structuralUnit.data.relationships.parentUnit?.data.attributes.fullName)
                        + ' -> ' + dismissal.data.relationships.contract.data.relationships.positionInfo.data[0].attributes.position
                },
                number: dismissal.data.relationships.contract.data.attributes.contractNumber ?
                    'შრომითი ხელშეკრულების N' + ': ' + dismissal.data.relationships.contract.data.attributes.contractNumber
                    : 'ბრძანების N' + ': ' + dismissal.data.relationships.contract.data.attributes.orderNumber,
                positionName: dismissal.data.relationships.contract.data.relationships.positionInfo.data[0].attributes.position,
                date: (0, parsers_1.nullableTransformDate)(dismissal.data.attributes.date),
                dismissalReason: exports.dismissalReasonOptions.find(el => el.id === String(dismissal.data.attributes.reason)),
                documentNumber: dismissal.data.attributes.documentNumber,
                title: dismissal.data.attributes.title
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDismissal = parseDismissal;
const parseMajorContractsForSelect = (response) => {
    if (response.content === null)
        return [];
    const contractMajors = response.content;
    try {
        return contractMajors.data.map(major => {
            return {
                id: major.id,
                label: (major.relationships.positionInfo.data[0].relationships.structuralUnit.data.attributes.type === types_2.StructuralUnitType.STRUCTURAL_UNIT
                    ? major.relationships.positionInfo.data[0].relationships.structuralUnit.data.attributes.fullName
                    : major.relationships.positionInfo.data[0].relationships.structuralUnit.data.relationships.parentUnit?.data.attributes.fullName)
                    + ' -> ' + major.relationships.positionInfo.data[0].attributes.position,
                positionName: major.relationships.positionInfo.data[0].attributes.position
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseMajorContractsForSelect = parseMajorContractsForSelect;
exports.dismissalReasonOptions = [
    { id: String(types_1.DismissalReasonType.END_DATE), label: 'ა) შრომითი ხელშეკრულების ვადის გასვლა' },
    { id: String(types_1.DismissalReasonType.JOB_PERFORMANCE), label: 'ბ) შრომითი ხელშეკრულებით გათვალისწინებული სამუშაოს შესრულება' },
    { id: String(types_1.DismissalReasonType.OWN_WILL), label: 'გ) დასაქმებულის საკუთარი ნებით, წერილობითი განცხადების საფუძველზე თანამდებობის/სამუშაოს დატოვება' },
    { id: String(types_1.DismissalReasonType.AGREEMENT), label: 'დ) მხარეთა წერილობითი შეთანხმება' },
    { id: String(types_1.DismissalReasonType.GROSS_VIOLATION_OBLIGATIONS), label: 'ე) დასაქმებულის მიერ მისთვის ინდივიდუალური შრომითი ხელშეკრულებით დაკისრებული ვალდებულების უხეში დარღვევა' },
    { id: String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE), label: 'ვ) უნივერსიტეტის ეთიკის კოდექსის, შრომის შინაგანაწესით დაკისრებული ვალდებულებების და განსაზღვრული ნორმების უხეში დარღვევა' },
    { id: String(types_1.DismissalReasonType.INABILITY_TO_WORK), label: 'ზ) ხელშეკრულებით განსაზღვრული ხანგრძლივი შრომისუუნარობა' },
    { id: String(types_1.DismissalReasonType.INCOMPATIBILITY_WORK), label: 'თ) დასაქმებულის კვალიფიკაციის ან პროფესიული უნარ-ჩვევების მის მიერ დაკავებულ თანამდებობასთან/შესასრულებელ სამუშაოსთან შეუსაბამობა' },
    { id: String(types_1.DismissalReasonType.COUNT_VERDICT), label: 'ი) სასამართლო განაჩენის ან სხვა გადაწყვეტილების კანონიერ ძალაში შესვლა, რომელიც სამუშაოს შესრულების შესაძლებლობას გამორიცხავს' },
    { id: String(types_1.DismissalReasonType.DEATH), label: 'კ) გარდაცვალება' },
    { id: String(types_1.DismissalReasonType.OTHER_OBJECTIVE_CIRCUMSTANCE), label: 'ლ) სხვა ობიექტური გარემოება' },
    { id: String(types_1.DismissalReasonType.VIOLATION_OBLIGATIONS), label: 'მ) დასაქმებულის მიერ მისთვის ინდივიდუალური შრომითი ხელშეკრულებით ან/და შრომის შინაგანაწესით დაკისრებული ვალდებულების დარღვევა, თუ მის მიმართ ბოლო 1 წლის განმავლობაში უკვე გამოყენებული იყო ინდივიდუალური შრომითი ხელშეკრულებით ან/და შრომის შინაგანაწესით გათვალისწინებული დისციპლინური პასუხისმგებლობის რომელიმე ზომა' },
    { id: String(types_1.DismissalReasonType.REDUCING_NECESSARY_WORKFORCE), label: 'ნ) ეკონომიკური გარემოებები, ტექნოლოგიური ან ორგანიზაციული ცვლილებები, რომელიც აუცილებელს ხდის სამუშაო ძალის შემცირებას' },
    { id: String(types_1.DismissalReasonType.COURT_RECOGNITION_AS_LIMITED_CAPACITY), label: 'ო) ადმინისტრაციული თანამდებობის შემთხვევაში სასამართლოს მიერ შეზღუდულქმედუნარიანად აღიარება ან მხარდაჭერის მიმღებად ცნობა, თუ სასამართლოს გადაწყვეტილებით სხვა რამ არ არის განსაზღვრული' },
    { id: String(types_1.DismissalReasonType.REACHING_65_ADMINISTRATIVE_POSITION), label: 'პ) ადმინისტრაციული თანამდებობის შემთხვევაში 65 წლის ასაკის მიღწევა' },
    { id: String(types_1.DismissalReasonType.PREREQUISITE_FOR_ADMINISTRATIVE_POSITION), label: 'ჟ) აკადემიური თანამდებობიდან გათავისუფლება, თუ ამ აკადემიურ თანამდებობაზე ყოფნა შესაბამისი ადმინისტრაციული თანამდებობის დაკავების წინაპირობაა' },
    { id: String(types_1.DismissalReasonType.OPTION_EXPIRATION), label: 'რ) შესაბამის თანამდებობაზე არჩევის ვადის გასვლა' },
    { id: String(types_1.DismissalReasonType.AWARDING_EMERITUS), label: 'ს) აკადემიური პერსონალისათვის ემერიტუსის წოდების მინიჭება' },
    { id: String(types_1.DismissalReasonType.NON_FULFILMENT_CERTIFICATION_CONDITIONS), label: 'ტ) ისეთი ობიექტური გარემოება, როგორიცაა აკადემიური პერსონალის მიერ ატესტაციის პირობების დაუკმაყოფილებლობა' },
    { id: String(types_1.DismissalReasonType.FIGHT_AGAINST_CRIMES_AGAINST_SEXUAL_FREEDOM_AND_INTEGRITY), label: 'უ) ისეთი ობიექტური გარემოება, როგორიცაა ,,სქესობრივი თავისუფლებისა და ხელშეუხებლობის წინააღმდეგ მიმართულ დანაშაულთან ბრძოლის შესახებ“ საქართველოს კანონით გათვალისწინებული დანაშაულის ჩადენა' },
    { id: String(types_1.DismissalReasonType.TERMINATION_OF_FUNDING), label: 'ფ) სახელმწიფო საბიუჯეტო მიზნობრივი(მათ შორის, პროგრამული) დაფინანსების შეწყვეტა' },
    { id: String(types_1.DismissalReasonType.ABOLITION_OF_SCIENTIFIC_RESEARCH_UNIT), label: 'ქ) დამოუკიდებელი სამეცნიერო-კვლევითი ერთეულის გაუქმება' },
    { id: String(types_1.DismissalReasonType.INITIATION_OF_UNIVERSITY_LIQUIDATION_PROCEEDING), label: 'ღ) უნივერსიტეტის ლიკვიდაციის წარმოების დაწყება' },
    { id: String(types_1.DismissalReasonType.CHANGE_POSITION), label: 'ყ) გათავისფლება პოზიციის ცვლილების გამო' }
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAdministrativeBuildingsForSelect = exports.parseAdministrativeBuilding = exports.parseAdministrativeBuildings = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseAdministrativeBuildings = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const administrativeBuildings = response.content;
    try {
        return {
            data: administrativeBuildings.data.map(el => {
                return {
                    id: el.id,
                    name: el.attributes.name,
                    address: el.attributes.address,
                    lat: el.attributes.lat,
                    lng: el.attributes.lng,
                    creator: el.relationships.creator.data.relationships.user.data.attributes.fullname,
                    structuralUnitName: el.relationships.creator.data.relationships.structuralUnit?.data.attributes.fullName,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(administrativeBuildings.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAdministrativeBuildings = parseAdministrativeBuildings;
const parseAdministrativeBuilding = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const administrativeBuilding = response.content;
    try {
        return {
            data: {
                id: administrativeBuilding.data.id,
                name: administrativeBuilding.data.attributes.name,
                address: administrativeBuilding.data.attributes.address,
                coordinates: administrativeBuilding.data.attributes.lat + ', ' + administrativeBuilding.data.attributes.lng,
                lat: administrativeBuilding.data.attributes.lat,
                lng: administrativeBuilding.data.attributes.lng,
                district: {
                    id: administrativeBuilding.data.relationships.district.data.id,
                    label: administrativeBuilding.data.relationships.district.data.attributes.nameEn
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAdministrativeBuilding = parseAdministrativeBuilding;
const parseAdministrativeBuildingsForSelect = (response) => {
    if (response.content === null)
        return [];
    const administrativeBuildings = response.content;
    try {
        return administrativeBuildings.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.name
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseAdministrativeBuildingsForSelect = parseAdministrativeBuildingsForSelect;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceType = exports.IncomeType = exports.InvoiceSubStatuses = exports.InvoiceStatuses = exports.InvoiceSignatoryType = void 0;
var InvoiceSignatoryType;
(function (InvoiceSignatoryType) {
    InvoiceSignatoryType[InvoiceSignatoryType["DEMANDING"] = 1] = "DEMANDING";
    InvoiceSignatoryType[InvoiceSignatoryType["PERFORMER"] = 2] = "PERFORMER";
    InvoiceSignatoryType[InvoiceSignatoryType["ADMINISTRATION"] = 3] = "ADMINISTRATION";
    InvoiceSignatoryType[InvoiceSignatoryType["OTHERS"] = 4] = "OTHERS";
})(InvoiceSignatoryType || (exports.InvoiceSignatoryType = InvoiceSignatoryType = {}));
var InvoiceStatuses;
(function (InvoiceStatuses) {
    InvoiceStatuses[InvoiceStatuses["DRAFT"] = 1] = "DRAFT";
    InvoiceStatuses[InvoiceStatuses["DISMISSED"] = 2] = "DISMISSED";
    InvoiceStatuses[InvoiceStatuses["CONFIRM_PROCESS"] = 3] = "CONFIRM_PROCESS";
    InvoiceStatuses[InvoiceStatuses["CONFIRMED"] = 4] = "CONFIRMED";
    InvoiceStatuses[InvoiceStatuses["SIGNED"] = 5] = "SIGNED";
    InvoiceStatuses[InvoiceStatuses["SUSPENDED"] = 6] = "SUSPENDED";
})(InvoiceStatuses || (exports.InvoiceStatuses = InvoiceStatuses = {}));
var InvoiceSubStatuses;
(function (InvoiceSubStatuses) {
    InvoiceSubStatuses[InvoiceSubStatuses["SUB_STATUS_UNCOMPLETED"] = 1] = "SUB_STATUS_UNCOMPLETED";
    InvoiceSubStatuses[InvoiceSubStatuses["SUB_STATUS_COMPLETED"] = 2] = "SUB_STATUS_COMPLETED";
})(InvoiceSubStatuses || (exports.InvoiceSubStatuses = InvoiceSubStatuses = {}));
var IncomeType;
(function (IncomeType) {
    IncomeType[IncomeType["ECONOMICAL_ACTIVITIES"] = 1] = "ECONOMICAL_ACTIVITIES";
    IncomeType[IncomeType["NOT_REGISTERED_GRANT"] = 2] = "NOT_REGISTERED_GRANT";
    IncomeType[IncomeType["STATE_BUDGET_FUNDING"] = 3] = "STATE_BUDGET_FUNDING";
    IncomeType[IncomeType["REGISTERED_GRANT_FUNDING"] = 4] = "REGISTERED_GRANT_FUNDING";
    IncomeType[IncomeType["STATE_BUDGET_PROGRAM_FUNDING"] = 5] = "STATE_BUDGET_PROGRAM_FUNDING";
    IncomeType[IncomeType["FUNDS_BY_RUSTAVELI_FONDS"] = 6] = "FUNDS_BY_RUSTAVELI_FONDS";
    IncomeType[IncomeType["RUSTAVELI_FUND"] = 7] = "RUSTAVELI_FUND";
})(IncomeType || (exports.IncomeType = IncomeType = {}));
var InvoiceType;
(function (InvoiceType) {
    InvoiceType[InvoiceType["DEFAULT"] = 1] = "DEFAULT";
    InvoiceType[InvoiceType["ONLY_FILE"] = 2] = "ONLY_FILE";
    InvoiceType[InvoiceType["ADDITIONAL"] = 3] = "ADDITIONAL";
})(InvoiceType || (exports.InvoiceType = InvoiceType = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendPurchaseReportRequest = exports.getReportFields = exports.getPurchaseReport = exports.getPurchaseReports = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/purchases/report/parsers");
const getPurchaseReports = async (params) => {
    const response = await Api.get('reports/purchases', params);
    return (0, parsers_1.parsePurchaseReports)(response);
};
exports.getPurchaseReports = getPurchaseReports;
const getPurchaseReport = async (id) => {
    const response = await Api.get('reports/purchases/' + id);
    return (0, parsers_1.parsePurchaseReport)(response);
};
exports.getPurchaseReport = getPurchaseReport;
const getReportFields = async () => {
    const response = await Api.get('reports/purchases/export-fields');
    return (0, parsers_1.parseReportFields)(response);
};
exports.getReportFields = getReportFields;
const sendPurchaseReportRequest = async (params) => {
    return await Api.get('reports/purchases/export', params);
};
exports.sendPurchaseReportRequest = sendPurchaseReportRequest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const api_1 = require("src/api");
const purchaseReports_1 = require("src/modules/documents/purchases/report/store/purchaseReports");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("src/modules/documents/purchases/report/helpers");
const react_router_dom_1 = require("react-router-dom");
const usePurchaseReport = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, purchaseReports_1.usePurchaseReportsReducer)();
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [openModal, setOpenModal] = (0, react_1.useState)(null);
    const [openDownloadModal, setOpenDownloadModal] = (0, react_1.useState)(false);
    const [sendingExportRequest, setSendingExportRequest] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const getPurchaseReports = async (params) => {
        dispatch({ type: 'SEND_REPORT_REQUEST' });
        const filterParams = (0, helpers_1.transformFilterFormValuesToFilterParams)(params);
        const purchaseReport = await api_1.PurchaseReportApi.getPurchaseReports(filterParams);
        if (isMounted?.current) {
            navigate('/documents/purchases/report' + (0, queryString_1.stringify)((0, helpers_1.transformFilterFormValuesToUrl)(params)), { replace: true });
        }
        dispatch({ type: 'DONE_REPORT_REQUEST', payload: purchaseReport });
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        if (params.deliveryDateStart || params.deliveryDateEnd
            || params.contractDateStart || params.contractDateEnd
            || params.advanceGuaranteeValidityDateStart || params.advanceGuaranteeValidityDateEnd
            || params.contractValidityDateStart || params.contractValidityDateEnd
            || params.guaranteeDateStart || params.guaranteeDateEnd
            || params.keyword)
            setShowFilter(true);
        getPurchaseReports(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
    };
    const filter = (name, value, filterValues) => {
        getPurchaseReports({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: filterValues.page ? 1 : undefined }, getPurchaseReports);
    };
    const selectPerPage = (perPage, values) => {
        getPurchaseReports({ ...values, perPage, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getPurchaseReports({ ...values, page }).then();
    };
    const sendPurchaseReportRequest = async (filters, fields) => {
        const filterParams = (0, helpers_1.transformFilterFormValuesToFilterParams)(filters);
        setSendingExportRequest(true);
        const exportResponse = await api_1.PurchaseReportApi.sendPurchaseReportRequest({ filters: filterParams.filters, fields });
        setSendingExportRequest(false);
        return exportResponse;
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        showFilter,
        setShowFilter,
        selectPerPage,
        navigatePagination,
        initializeFilterForm,
        setOpenModal,
        openModal,
        filter,
        debouncedSearch,
        openDownloadModal,
        setOpenDownloadModal,
        sendingExportRequest,
        setSendingExportRequest,
        sendPurchaseReportRequest
    };
};
exports.default = usePurchaseReport;

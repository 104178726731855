"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeDismissalsFormValidation = exports.EmployeeAssignmentValidations = exports.EmployeeFamilyMemberValidations = exports.EmployeeTaxValidations = exports.EmployeeLanguagesFormValidation = exports.EmployeeTrainingsFormValidation = exports.EmployeeHighEducationValidations = exports.EmployeeBankAccountValidations = exports.EmployeeContactInfoValidation = exports.EmployeeFormValidation = void 0;
const types_1 = require("api/references/employees/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const validates_1 = require("core/helpers/validates");
const validate_1 = require("core/helpers/validate");
// TOTO
const EmployeeFormValidation = (values, translate) => {
    const formErrors = {
        firstname: (0, validates_1.required)(values.firstname, translate),
        lastname: (0, validates_1.required)(values.lastname, translate),
        // fathersName: required(values.fathersName),
        identityNumber: (0, validates_1.requiredPersonalNumber)(values.identityNumber, translate),
        idCardValidTo: (0, validates_1.required)(values.idCardValidTo, translate),
        passportNumber: (0, validates_1.required)(values.passportNumber, translate),
        passportValidTo: (0, validates_1.required)(values.passportValidTo, translate),
        gender: (0, validates_1.required)(values.gender, translate),
        country: (0, validates_1.required)(values.country, translate),
        birthDate: (0, validates_1.required)(values.birthDate, translate),
        actualAddress: (0, validates_1.required)(values.actualAddress, translate),
        mobileNumber: (0, validates_1.required)(values.mobileNumber, translate),
        workPhoneNumber: (0, validates_1.required)(values.workPhoneNumber, translate),
        personalEmail: (0, validate_1.requiredEmail)(values.personalEmail, translate),
        email: (0, validates_1.requiredCorporateEmail)(values.email, translate)
        // status: required(values.status),
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeFormValidation = EmployeeFormValidation;
const EmployeeContactInfoValidation = (values, translate) => {
    const formErrors = {
        // personalCaseNumber: required(values.personalCaseNumber),
        firstname: (0, validates_1.required)(values.firstname, translate) || (0, validates_1.checkSymbolsAndNumbersGE)(values.firstname, translate),
        lastname: (0, validates_1.required)(values.lastname, translate) || (0, validates_1.checkSymbolsAndNumbersGE)(values.lastname, translate),
        firstnameLatin: (0, validates_1.checkSymbolsAndNumbersLA)(values.firstnameLatin, translate),
        lastnameLatin: (0, validates_1.checkSymbolsAndNumbersLA)(values.lastnameLatin, translate),
        identityNumber: values.foreignCitizen === types_1.Citizen.GEORGIAN
            || (values.foreignCitizen === types_1.Citizen.FOREIGN && (values.residenceCertificateEndDate || values.residenceCertificateNumber))
            ? (0, validates_1.requiredPersonalNumber)(values.identityNumber, translate) : undefined,
        // idCardValidTo: required(values.idCardValidTo),
        passportNumber: values.foreignCitizen === types_1.Citizen.FOREIGN ? (0, validates_1.required)(values.passportNumber, translate) : undefined,
        passportValidTo: values.foreignCitizen === types_1.Citizen.FOREIGN ? (0, validates_1.required)(values.passportValidTo, translate) : undefined,
        residenceCertificateNumber: values.foreignCitizen !== types_1.Citizen.GEORGIAN
            ? values.residenceCertificateEndDate || (values.foreignCitizen === types_1.Citizen.FOREIGN && values.identityNumber)
                ? (0, validates_1.required)(values.residenceCertificateNumber, translate)
                : undefined
            : undefined,
        residenceCertificateEndDate: values.residenceCertificateNumber || (values.foreignCitizen === types_1.Citizen.FOREIGN && values.identityNumber)
            ? (0, validates_1.required)(values.residenceCertificateEndDate, translate)
            : undefined,
        country: values.foreignCitizen === types_1.Citizen.FOREIGN ? (0, validates_1.required)(values.country, translate) : undefined,
        administrativeBuilding: !values.noWorkPlace && values.administrativeBuildingRoom ? (0, validates_1.required)(values.administrativeBuilding, translate) : undefined
        // gender: required(values.gender),
        // birthDate: required(values.birthDate),
        // actualAddress: required(values.actualAddress),
        // mobileNumber: required(values.mobileNumber),
        // workPhoneNumber: required(values.workPhoneNumber),
        // personalEmail: requiredEmail(values.personalEmail),
        // email: requiredCorporateEmail(values.email),
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeContactInfoValidation = EmployeeContactInfoValidation;
const EmployeeBankAccountValidations = (values, translate) => {
    const formErrors = {
        bank: (0, validates_1.required)(values.bank, translate),
        accountNumber: (0, validates_1.required)(values.accountNumber, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeBankAccountValidations = EmployeeBankAccountValidations;
const EmployeeHighEducationValidations = (values, translate) => {
    const formErrors = {
        academicDegree: (0, validates_1.required)(values.academicDegrees, translate),
        assign: (0, validates_1.required)(values.assign, translate),
        dateOfAwardQualifications: (0, validates_1.required)(values.dateOfAwardQualifications, translate),
        diplomaNumber: (0, validates_1.required)(values.diplomaNumber, translate),
        educationType: (0, validates_1.required)(values.educationType, translate),
        educationalInstitution: (0, validates_1.required)(values.educationalInstitution, translate),
        faculty: (0, validates_1.required)(values.faculty, translate),
        qualification: (0, validates_1.required)(values.qualification, translate),
        releaseDate: (0, validates_1.required)(values.releaseDate, translate),
        specialty: (0, validates_1.required)(values.specialty, translate),
        studyStartDate: (0, validates_1.required)(values.studyStartDate, translate),
        titleOfDoctoralTopic: (0, validates_1.required)(values.titleOfDoctoralTopic, translate),
        titleOfMastersTopic: (0, validates_1.required)(values.titleOfMastersTopic, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeHighEducationValidations = EmployeeHighEducationValidations;
const EmployeeTrainingsFormValidation = (values, translate) => {
    const formErrors = {
        name: (0, validates_1.required)(values.name, translate),
        dateFrom: (0, validates_1.required)(values.dateFrom, translate),
        fileUids: values.files.every(el => !el.id) ? translate('required_upload_file', 'სავალდებულო ფაილის ატვირთვა') : undefined
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeTrainingsFormValidation = EmployeeTrainingsFormValidation;
const EmployeeLanguagesFormValidation = (values, translate) => {
    const formErrors = {
        language: (0, validates_1.required)(values.language, translate),
        level: (0, validates_1.required)(values.level, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeLanguagesFormValidation = EmployeeLanguagesFormValidation;
const EmployeeTaxValidations = (values, translate) => {
    const formErrors = {
        taxReliefAmount: values.useOfTaxRelief ? (0, validates_1.required)(values.taxReliefAmount, translate) : undefined,
        errorTaxOptions: values.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES
            ? !values.taxReliefId ? 'სავალდებულოა, აირჩიოთ შეღავათის ტიპი' : undefined
            : undefined
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeTaxValidations = EmployeeTaxValidations;
const EmployeeFamilyMemberValidations = (values, translate) => {
    const formErrors = {
        firstname: (0, validates_1.required)(values.firstname, translate),
        lastname: (0, validates_1.required)(values.lastname, translate),
        phone: (0, validates_1.required)(values.phone, translate)
    };
    const errors = {};
    Object.entries(formErrors).map(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeFamilyMemberValidations = EmployeeFamilyMemberValidations;
const EmployeeAssignmentValidations = (values, translate) => {
    const formErrors = {
        // assignCategory: required(values.assignCategory),
        // recordNumber: values.assignCategory === AssignCategory.ELECTED ? required(values.recordNumber) : undefined,
        // recordDate: values.assignCategory === AssignCategory.ELECTED ? required(values.recordDate) : undefined,
        // title: values.assignCategory === AssignCategory.ELECTED ? required(values.title) : undefined,
        // effectiveDate: values.assignCategory === AssignCategory.ELECTED ? required(values.effectiveDate) : undefined,
        // assignType: required(values.assignType?.id),
        // contractContractNumber: Number(values.assignType?.id) === AssignType.CONTRACT ? required(values.contractContractNumber) : undefined,
        // contractContractDate: Number(values.assignType?.id) === AssignType.CONTRACT ? required(values.contractContractDate) : undefined,
        // contractContractType: Number(values.assignType?.id) === AssignType.CONTRACT ? required(values.contractContractType) : undefined,
        // contractExtContractNumber: Number(values.assignType?.id) === AssignType.CONTRACT_EXT ? required(values.contractExtContractNumber) : undefined,
        // contractExtContractDate: Number(values.assignType?.id) === AssignType.CONTRACT_EXT ? required(values.contractExtContractDate) : undefined,
        // contractExtContractType: Number(values.assignType?.id) === AssignType.CONTRACT_EXT ? required(values.contractExtContractType) : undefined,
        // orderOrderNumber: Number(values.assignType?.id) === AssignType.ORDER ? required(values.orderOrderNumber) : undefined,
        // orderOrderDate: Number(values.assignType?.id) === AssignType.ORDER ? required(values.orderOrderDate) : undefined,
        // orderOrderType: Number(values.assignType?.id) === AssignType.ORDER ? required(values.orderOrderType) : undefined,
        // ocOrderNumber: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocOrderNumber) : undefined,
        // ocContractNumber: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocContractNumber) : undefined,
        // ocOrderDate: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocOrderDate) : undefined,
        // ocOrderType: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocOrderType) : undefined,
        // ocContractDate: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocContractDate) : undefined,
        // ocContractType: Number(values.assignType?.id) === AssignType.ORDER_CONTRACT ? required(values.ocContractType) : undefined,
        // contractContinuationType: Number(values.assignType?.id) === AssignType.CONTRACT ? required(values.employmentContractType) : undefined,
        // contractExtContinuationType: Number(values.assignType?.id) === AssignType.CONTRACT_EXT ? required(values.contractExtContinuationType) : undefined,
        positionInfoErrors: values.positionInfos.map(positionInfo => {
            return {
                // position: required(positionInfo.position),
                positionName: (0, validates_1.required)(positionInfo.positionName, translate),
                // positionType: required(positionInfo.positionType),
                // workPlaces: requiredNotEmptyArray(positionInfo.workPlaces),
                startDate: (0, validates_1.required)(positionInfo.startDate, translate)
                // basicFunctionDuties: required(positionInfo.basicFunctionDuties),
                // projectInfoErrors: positionInfo.projectInfos.map(projectInfo => {
                // 	return {
                // 		project: required(projectInfo.project),
                // 		workTypesInfoErrors: projectInfo.workInfos.map(workInfo => {
                // 			return {
                // 				workload: required(workInfo.workload),
                // 				salaryType: required(workInfo.salaryType),
                // 				salaryAmount: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.FIXED ? requiredPositiveNumber(workInfo.salaryAmount) : undefined,
                // 				salaryGivenType: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.FIXED ? required(workInfo.salaryGivenType) : undefined,
                // 				workType: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.BY_JOB ? required(workInfo.workType) : undefined,
                // 				remunerationWork: workInfo.salaryType && Number(workInfo.salaryType.id) === SalaryType.BY_JOB ? required(workInfo.remunerationWork) : undefined,
                // 				workTypeInfoItems: workInfo.salaryType && Number(workInfo.salaryType.id) !== SalaryType.FIXED
                // 					? Number(workInfo.salaryType.id) !== SalaryType.BY_JOB ? transformWorkInfoItemErrors(workInfo, positionInfo.startDate, positionInfo.endDate)
                // 						: workInfo.remunerationWork ? transformWorkInfoItemErrors(workInfo, positionInfo.startDate, positionInfo.endDate) : []
                // 					: []
                // 			}
                // 		})
                // 	}
                // })
            };
        })
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (key !== 'positionInfoErrors' && value) {
            errors[key] = value;
        }
        else {
            formErrors.positionInfoErrors = formErrors.positionInfoErrors?.map(el => {
                if (el && (el.positionName
                    || el.startDate
                // || el.workPlaces
                // || el.basicFunctionDuties
                // || el.position
                // || el.positionType
                // || ((el.projectInfoErrors as Array<ProjectInfoErrors>).some(
                // 	projectInfo => projectInfo.project
                //       || (projectInfo.workTypesInfoErrors as Array<WorkTypeInfoErrors>).some(
                //       	workType => workType.workload
                //           || workType.salaryType
                //           || workType.salaryAmount
                //           || workType.salaryGivenType
                //           || workType.workType
                //           || workType.remunerationWork
                //           || workType.workTypeInfoItems.some(
                //           	item => item.months
                //               || item.dateRange
                //               || item.salaryAmount
                //               || item.salaryGivenType
                //               || item.supplementAmount
                //               || item.unitName
                //               || item.amount
                //               || item.administrativeOrdersArticle
                //               || item.tariff
                //               || item.volumeWorkPerformed
                //               || item.year)))))
                )) {
                    return el;
                }
                return;
            });
            if (formErrors.positionInfoErrors.some(err => err)) {
                errors[key] = value;
            }
        }
    });
    return errors;
};
exports.EmployeeAssignmentValidations = EmployeeAssignmentValidations;
const EmployeeDismissalsFormValidation = (values, translate) => {
    const validReason = values.dismissalReason && (values.dismissalReason.id === String(types_1.DismissalReasonType.END_DATE)
        || values.dismissalReason.id === String(types_1.DismissalReasonType.JOB_PERFORMANCE)
        || values.dismissalReason.id === String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE)
        || values.dismissalReason.id === String(types_1.DismissalReasonType.OPTION_EXPIRATION)
        || values.dismissalReason.id === String(types_1.DismissalReasonType.AWARDING_EMERITUS));
    const formErrors = {
        assign: (0, validates_1.required)(values.assign, translate),
        dismissalReason: (0, validates_1.required)(values.dismissalReason, translate),
        dismissalDate: (0, validates_1.required)(values.dismissalDate, translate),
        documentNumber: validReason ? undefined : (0, validates_1.required)(values.documentNumber, translate),
        date: validReason ? undefined : (0, validates_1.required)(values.date, translate),
        title: validReason ? undefined : (0, validates_1.required)(values.title, translate)
    };
    const errors = {};
    Object.entries(formErrors).forEach(([key, value]) => {
        if (value)
            errors[key] = value;
    });
    return errors;
};
exports.EmployeeDismissalsFormValidation = EmployeeDismissalsFormValidation;
// export const SignatureAndApproveValidation = (values: SignatureAndApprove): SignatureAndApproveFormValidations => {
//
//   const formErrors: SignatureAndApproveFormValidations = {
//     signature: values.pending?.signature ? requiredPngFormat(values.pending.signature) : undefined ,
//     signatureApprove: values.pending?.signatureApprove ? requiredPhotoOrPdf(values.pending.signatureApprove) : undefined,
//   }
//   const errors: SignatureAndApproveFormValidations = {} as SignatureAndApproveFormValidations
//   Object.entries(formErrors).forEach(([key, value]) => {
//     if (value) errors[key as keyof SignatureAndApproveFormValidations] = value
//   })
//   return errors
// }
// const requiredPhotoFormat = (photo: SignatureAndApproveMediaFile | null) => {
//   const signatureIsImage = photo && imageExtensions.includes(photo.extension)
//   return photo === null || !photo.id || !signatureIsImage ? 'აუცილებელია ფოტო-ფორმატის ფაილის ატვირთვა' : undefined
// }
// const requiredPngFormat = (photo: SignatureAndApproveMediaFile | null) => {
//   const signatureIsPng = photo && photo.extension.toLowerCase() === 'png'
//   return photo === null || !photo?.id || !signatureIsPng ? 'აუცილებელია png გაფართოების ფაილის ატვირთვა' : undefined
// }
//
// const requiredPhotoOrPdf = (file: SignatureAndApproveMediaFile | null) => {
//   const signatureIsImageOrPdf = file && (imageExtensions.includes(file?.extension.toLowerCase()) || file?.extension.toLowerCase() === 'pdf')
//   return file === null || !file?.id || !signatureIsImageOrPdf ? 'აუცილებელია ფოტო-ფორმატის ან pdf გაფართოების ფაილის ატვირთვა' : undefined
// }

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseReportFields = exports.parsePurchaseReport = exports.parsePurchaseReports = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsers_2 = require("api/documents/purchases/procurements/parsers");
const parsePurchaseReports = (response) => {
    const purchaseReport = response.content;
    if (purchaseReport === null)
        return { data: null, errors: response.errors, status: response.status };
    try {
        return {
            data: purchaseReport.data.map(el => {
                return {
                    id: el.id,
                    signedDocumentNumber: el.attributes.signedDocumentNumber,
                    dateOfConclusion: (0, parsers_1.nullableTransformDate)(el.attributes.dateOfConclusion),
                    dateOfConclusionFull: (0, parsers_1.transformDateTime)(el.attributes.dateOfConclusion),
                    contractValidityDate: (0, parsers_1.nullableTransformDate)(el.attributes.contractValidityDate),
                    contractValidityDateFull: (0, parsers_1.transformDateTime)(el.attributes.contractValidityDate),
                    deliveryDate: (0, parsers_1.nullableTransformDate)(el.attributes.deliveryDate),
                    deliveryDateFull: (0, parsers_1.transformDateTime)(el.attributes.deliveryDate),
                    advanceGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(el.attributes.advanceGuaranteeValidityPeriod),
                    advanceGuaranteeValidityPeriodFull: (0, parsers_1.transformDateTime)(el.attributes.advanceGuaranteeValidityPeriod),
                    companyName: el.relationships.company.data.attributes.name
                };
            }),
            meta: (0, parsers_1.transformPagination)(purchaseReport.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePurchaseReports = parsePurchaseReports;
const parsePurchaseReport = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const purchaseReport = response.content;
    try {
        const reportAttributes = purchaseReport.data.attributes;
        return {
            data: {
                projects: reportAttributes.projects,
                status: (0, parsers_2.parseProcurementVisualStatus)(reportAttributes.status).name,
                sumPrice: reportAttributes.sumPrice,
                sumPriceInForeignCurrency: reportAttributes.sumPriceInForeignCurrency,
                subStatus: (0, parsers_2.parseProcurementSubStatus)(reportAttributes.subStatus).name,
                submissionNumbers: reportAttributes.submissionNumbers,
                submissionAuthors: reportAttributes.submissionAuthors,
                remark: reportAttributes.remark,
                paymentResource: reportAttributes.paymentResource,
                payment: reportAttributes.payment,
                creatorName: reportAttributes.creatorName,
                legalBasis: reportAttributes.legalBasis,
                itemsInfo: reportAttributes.itemsInfo,
                invoicesSumPriceForAccounting: reportAttributes.invoicesSumPriceForAccounting,
                invoicesFinesSumPrice: reportAttributes.invoicesFinesSumPrice,
                invoiceResource: reportAttributes.invoiceResource,
                fundingSources: reportAttributes.fundingSources,
                deliveredToAccounting: reportAttributes.deliveredToAccounting,
                crmSpaConNumbers: reportAttributes.crmSpaConNumbers,
                invoicesSumPriceForPurchase: reportAttributes.invoicesSumPriceForPurchase,
                cpvCodesNames: reportAttributes.cpvCodesNames,
                cpvCodes: reportAttributes.cpvCodes,
                companyDetails: reportAttributes.companyDetails,
                cmr: reportAttributes.cmr,
                budgetSources: reportAttributes.budgetSources,
                advanceGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(reportAttributes.advanceGuaranteeValidityPeriod),
                con: reportAttributes.con,
                templateType: reportAttributes.templateType,
                spa: reportAttributes.spa,
                contractGuaranteeValidityPeriod: (0, parsers_1.nullableTransformDate)(reportAttributes.contractGuaranteeValidityPeriod),
                tenderLink: reportAttributes.tenderLink
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePurchaseReport = parsePurchaseReport;
const parseReportFields = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const reportFields = response.content;
    try {
        const reportAttributes = reportFields.data.attributes;
        return {
            data: reportAttributes
                ? reportAttributes.map(reportField => {
                    return {
                        fieldKey: reportField.key,
                        fieldName: reportField.name
                    };
                }) : null,
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseReportFields = parseReportFields;

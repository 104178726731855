"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInvoices = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const invoices_1 = require("../store/invoices");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helper_1 = require("../helper");
const helpers_1 = require("core/helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useInvoices = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, invoices_1.useInvoicesReducer)();
    const [deletedInvoiceId, setDeletedInvoiceId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [invoiceId, setInvoiceId] = (0, react_1.useState)(null);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const filter = (name, value, filterValues) => {
        getInvoices({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        getInvoices({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        getInvoices({ ...values, perPage, page: 1 }).then();
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeletedInvoiceId(id);
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getInvoices);
    };
    const deleteInvoice = async (confirm) => {
        setShowDeleteAlert(false);
        if (!deletedInvoiceId || !confirm)
            return setDeletedInvoiceId(null);
        const invoices = await index_1.InvoicesApi.deleteInvoice(deletedInvoiceId);
        const params = (0, queryString_1.parse)(location.search);
        if (invoices.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getInvoices(params).then();
        setDeletedInvoiceId(null);
    };
    const getInvoices = async (values) => {
        dispatch({ type: 'SEND_INVOICES_REQUEST' });
        const pagination = { perPage: values.perPage ?? state.meta.pagination.perPage, page: values.page ?? state.meta.pagination.currentPage };
        const filterParams = (0, helper_1.transformInvoiceParamsToFilterParams)({ ...values, ...pagination });
        const invoices = await index_1.InvoicesApi.getInvoices(filterParams);
        if (invoices.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helper_1.transformFilterParamsToUrl)(values)));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_INVOICES_REQUEST', payload: invoices });
            navigate('/documents/purchases/invoices' + (0, queryString_1.stringify)((0, helper_1.transformFilterParamsToUrl)(values)));
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helper_1.getParamsFromUrl)(location.search);
        if ((0, helpers_1.isAnyFilterValue)(params))
            setShowFilter(true);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.invoiceListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 50;
        params.onlyMine = localStorage.getItem('invoiceOnlyMine') || undefined;
        const invoiceListId = (0, queryString_1.parse)(location.search).invoiceId;
        getInvoices(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setInvoiceId(invoiceListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        selectPerPage,
        debouncedSearch,
        navigatePagination,
        deletedInvoiceId,
        deleteInvoice,
        showDeleteAlert,
        linkParams,
        deleteAlert,
        filter,
        getInvoices,
        invoiceId,
        showFilter,
        setShowFilter
    };
};
exports.useInvoices = useInvoices;
exports.default = exports.useInvoices;

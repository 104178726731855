"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseAuthorizedAccountStatus = exports.parseAuthorizedAccounts = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseAuthorizedAccounts = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const authorizedAccounts = response.content;
    try {
        return {
            data: authorizedAccounts.data.map(el => {
                return {
                    id: el.id,
                    authorityGranter: el.relationships.authorizedForAccount.data.relationships.user.data.attributes.fullname
                        + ' ' + '(' + el.relationships.authorizedForAccount.data.relationships.structuralUnit.data.attributes.fullName + ')',
                    authority: el.relationships.authorizedAccount.data.relationships.user.data.attributes.fullname
                        + ' ' + '(' + el.relationships.authorizedUserAccount.data.relationships.structuralUnit.data.attributes.name + ')',
                    visualStatus: (0, exports.parseAuthorizedAccountStatus)(el.relationships.authorizedAccount.data.attributes.status),
                    startDate: (0, parsers_1.transformDate)(el.attributes.startDate),
                    startDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.startDate),
                    endDate: (0, parsers_1.transformDate)(el.attributes.endDate),
                    endDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.endDate)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(authorizedAccounts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAuthorizedAccounts = parseAuthorizedAccounts;
const parseAuthorizedAccountStatus = (status) => {
    switch (status) {
        case apiGlobalTypes_1.AccountStatus.ACTIVE:
            return { name: 'აქტიური', color: 'success' };
        case apiGlobalTypes_1.AccountStatus.PASSIVE:
            return { name: 'პასიური', color: 'secondary' };
        case apiGlobalTypes_1.AccountStatus.PENDING:
            return { name: 'მომლოდინე', color: 'warning' };
    }
};
exports.parseAuthorizedAccountStatus = parseAuthorizedAccountStatus;

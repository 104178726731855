"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StructuralUnitContractReducer = exports.useStructuralUnitContractReducer = void 0;
const react_1 = require("react");
const initialStructuralUnitContractValues = {
    startDate: '',
    endDate: '',
    createdAt: '',
    updatedAt: '',
    user: null,
    structuralUnit: null
};
const initialStructuralUnitForm = {
    data: initialStructuralUnitContractValues,
    errors: null,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isLoading: false,
    isDeleting: false,
    isStructuralFetching: false
};
const useStructuralUnitContractReducer = () => {
    return (0, react_1.useReducer)(exports.StructuralUnitContractReducer, initialStructuralUnitForm);
};
exports.useStructuralUnitContractReducer = useStructuralUnitContractReducer;
const StructuralUnitContractReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HR_CONTRACTS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_HR_CONTRACTS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                isFetching: false,
                isLoading: state.isUpdating || state.isCreating,
                errors: action.payload.errors
            };
        case 'SEND_CREATE_HR_CONTRACTS':
            return {
                ...state,
                isCreating: true,
                isLoading: true
            };
        case 'DONE_CREATE_HR_CONTRACTS':
            return {
                ...state,
                data: action.payload.data || state.data,
                isCreating: false,
                isLoading: state.isUpdating || state.isFetching,
                errors: action.payload.errors
            };
        case 'SEND_HR_CONTRACTS_UPDATE':
            return {
                ...state,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_HR_CONTRACTS_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                isUpdating: false,
                isLoading: state.isFetching || state.isCreating,
                errors: action.payload.errors
            };
        case 'SEND_HR_CONTRACTS_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true,
                isLoading: true
            };
        case 'DONE_HR_CONTRACTS_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false,
                data: action.payload.status
                    ? { ...initialStructuralUnitContractValues, structuralUnit: state.data.structuralUnit }
                    : state.data,
                isLoading: state.isFetching || state.isUpdating || state.isCreating
            };
        case 'INITIALIZE_CREATE_FORM_REQUEST':
            return {
                ...state,
                isStructuralFetching: true
            };
        case 'INITIALIZE_CREATE_FORM':
            return {
                ...state,
                data: {
                    ...initialStructuralUnitContractValues,
                    structuralUnit: action.payload
                },
                isStructuralFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.StructuralUnitContractReducer = StructuralUnitContractReducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/documents/purchases/procurements/types");
const additionalFiles_1 = require("src/modules/documents/purchases/procurements/store/additionalFiles");
const api_1 = require("src/api");
const helpers_1 = require("src/modules/documents/purchases/procurements/helpers");
const react_1 = require("react");
const useProcurementAdditionalFiles = (props) => {
    const [state, dispatch] = (0, additionalFiles_1.useProcurementAdditionalFilesReducer)();
    const getAdditionalFiles = async (id) => {
        dispatch({ type: 'SEND_PROCUREMENT_ADDITIONAL_FILES_REQUEST' });
        const response = await api_1.ProcurementsApi.getAdditionalFiles(id);
        dispatch({ type: 'DONE_PROCUREMENT_ADDITIONAL_FILES_REQUEST', payload: response });
    };
    const addAdditionalFiles = async (id, values) => {
        const params = (0, helpers_1.transformProcurementAdditionalFilesParams)(values);
        dispatch({ type: 'SEND_PROCUREMENT_ADD_ADDITIONAL_FILES' });
        const response = await api_1.ProcurementsApi.addAdditionalFiles(id, params);
        dispatch({ type: 'DONE_PROCUREMENT_ADD_ADDITIONAL_FILES', payload: response });
        return response;
    };
    (0, react_1.useEffect)(() => {
        if (props.id
            && (props.status === types_1.ProcurementStatuses.SIGNED
                || props.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                || props.status === types_1.ProcurementStatuses.CORRECTED)) {
            getAdditionalFiles(props.id).then();
        }
    }, [props.id, props.status]);
    return {
        state,
        getAdditionalFiles,
        addAdditionalFiles
    };
};
exports.default = useProcurementAdditionalFiles;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const helpers_1 = require("../../procurements/helpers");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const useProcurementModal = () => {
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [errors, setErrors] = (0, react_1.useState)(null);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const createProcurementWithItems = async (values) => {
        const params = (0, helpers_1.transformProcurementParams)(values, true);
        setIsCreating(true);
        const procurement = await index_1.ProcurementsApi.createProcurement(params, true);
        if (procurement.errors !== null)
            setIsCreating(false);
        setErrors(procurement.errors);
        return procurement;
    };
    const createOrUpdateProcurementItem = async (itemsApproved, procurementId) => {
        const params = (0, helpers_1.transformProcurementItemsListParams)(itemsApproved);
        const verifyItems = await index_1.ProcurementsApi.updateProcurementItem(procurementId, params);
        navigate(`/documents/purchases/procurements/${procurementId}/edit`);
        setIsCreating(false);
        return verifyItems;
    };
    const closeErrorMessage = (errorIndex) => {
        if (errors)
            setErrors(errors.filter((_el, index) => errorIndex !== index));
    };
    return {
        createProcurementWithItems,
        isCreating,
        errors,
        closeErrorMessage,
        createOrUpdateProcurementItem
    };
};
exports.default = useProcurementModal;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitNameOptions = exports.RemunerationWorkPerformedOptions = exports.workLoadOption = exports.salaryGivenTypeOptions = exports.salaryTypeOptions = exports.parseAddressee = exports.parseItemFile = exports.parseHumanResourceSubmissionStatusName = exports.parseHumanResourceSubmissionItemStatusName = exports.parseProjectInfos = exports.parseEmployeePositionV2 = exports.parseEmployeePosition = exports.parseFileType = exports.parseHRAssignSubmissionFormItem = exports.parseHRAssignSubmissionFormItems = exports.parseHumanResourceSubmissionItems = exports.parseAssignSubmissionFiles = exports.parseAddresseesAndPosition = exports.parseHumanResourceSubmission = exports.parseHumanResourceSubmissions = void 0;
const types_1 = require("./types");
const types_2 = require("api/references/funding/projects/types");
const types_3 = require("api/references/structuralUnitsUpgraded/types");
const helper_1 = require("src/modules/common/helper");
const helper_2 = require("api/helper");
const parsers_1 = require("api/files/parsers");
const useCreateOrUpdateItems_1 = require("src/modules/documents/hr/assign-submissions/store/useCreateOrUpdateItems");
const contsants_1 = require("core/helpers/contsants");
const parsers_2 = require("api/parsers/parsers");
const parsers_3 = require("api/references/funding/projects/parsers");
const parseHumanResourceSubmissions = (response) => {
    if (response.content === null)
        return {
            data: null,
            errors: response.errors,
            status: response.status
        };
    const humanResourceSubmissions = response.content;
    try {
        return {
            data: humanResourceSubmissions.data.map(el => {
                const structuralUnit = el.relationships.requesterAccount.data.relationships.structuralUnit;
                const project = el.relationships.requesterAccount.data.relationships.project;
                return {
                    id: el.id,
                    type: el.type,
                    number: el.attributes.number,
                    visualStatus: (0, exports.parseHumanResourceSubmissionStatusName)(el.attributes.status),
                    visualSentBy: el.attributes.sentBy && parseHumanResourceSubmissionSentBy(el.attributes.sentBy),
                    status: el.attributes.status,
                    requesterFullName: el.relationships.requesterAccount.data.relationships.user.data.attributes.fullname,
                    requesterPosition: structuralUnit ? structuralUnit.data.attributes.name : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    addressees: el.relationships.addressees.data.map(item => {
                        const addresseesStructuralUnit = item.relationships.account.data.relationships.structuralUnit;
                        const addresseesStructuralUnitProject = item.relationships.account.data.relationships.project;
                        return {
                            name: item.relationships.account.data.relationships.user.data.attributes.fullname,
                            position: addresseesStructuralUnit ? addresseesStructuralUnit.data.attributes.name : addresseesStructuralUnitProject.data.attributes.name
                        };
                    }),
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_2.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            status: response.status,
            meta: (0, parsers_2.transformPagination)(humanResourceSubmissions.meta)
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseHumanResourceSubmissions = parseHumanResourceSubmissions;
const parseHumanResourceSubmission = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const humanResourceSubmission = response.content.data;
    try {
        const status = humanResourceSubmission.attributes.status;
        const project = humanResourceSubmission.relationships.requesterAccount.data.relationships.project;
        const structuralUnit = humanResourceSubmission.relationships.requesterAccount.data.relationships.structuralUnit;
        const creatorStructuralUnit = humanResourceSubmission.relationships.creator.data.relationships.structuralUnit;
        const creatorProject = humanResourceSubmission.relationships.creator.data.relationships.project;
        return {
            data: {
                id: humanResourceSubmission.id,
                edocNumber: humanResourceSubmission.attributes.edocNumber,
                requestRegistrationDate: (0, parsers_2.nullableTransformDate)(humanResourceSubmission.attributes.requestRegistrationDate),
                number: humanResourceSubmission.attributes.number,
                status: humanResourceSubmission.attributes.status,
                sentBy: humanResourceSubmission.attributes.sentBy,
                sentDate: (0, parsers_2.nullableTransformDate)(humanResourceSubmission.attributes.sentDate),
                visualStatus: (0, exports.parseHumanResourceSubmissionStatusName)(humanResourceSubmission.attributes.status),
                visualSentBy: humanResourceSubmission.attributes.sentBy && parseHumanResourceSubmissionSentBy(humanResourceSubmission.attributes.sentBy),
                disabledStatus: status !== types_1.HRAssignSubmissionStatuses.DRAFT,
                declineComment: humanResourceSubmission.attributes.declineComment,
                usedItemsCount: humanResourceSubmission.attributes.usedItemsCount,
                itemsCount: humanResourceSubmission.attributes.itemsCount,
                addressees: humanResourceSubmission.relationships.addressees.data.map(addressee => (0, exports.parseAddressee)(addressee)),
                submissionAuthor: {
                    id: humanResourceSubmission.relationships.requesterAccount.data.relationships.user.data.id,
                    label: humanResourceSubmission.relationships.requesterAccount.data.relationships.user.data.attributes.fullname +
                        `${humanResourceSubmission.relationships.requesterAccount.data.relationships.user.data.attributes.email
                            ? ` (${humanResourceSubmission.relationships.requesterAccount.data.relationships.user.data.attributes.email})` : ''}`,
                    structuralUnits: humanResourceSubmission.relationships.requesterAccount.data.relationships.user.data.relationships.accounts.data.map(unit => {
                        const unitStructuralUnit = unit.relationships.structuralUnit;
                        const unitProject = unit.relationships.project;
                        return {
                            id: unitStructuralUnit ? unitStructuralUnit.data.id : '',
                            accountId: unit.id,
                            label: unitStructuralUnit ? unitStructuralUnit.data.attributes.name : 'პროექტის "' + unitProject.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                        };
                    })
                },
                authorStructuralUnit: {
                    id: structuralUnit ? structuralUnit.data.id : '',
                    accountId: humanResourceSubmission.relationships.requesterAccount.data.id,
                    label: structuralUnit ? structuralUnit.data.attributes.name : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                files: humanResourceSubmission.relationships.mediaFiles.data.map(file => {
                    return {
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        type: file.attributes.pivots.type,
                        originalName: file.attributes.originalName,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension
                    };
                }),
                purpose: humanResourceSubmission.attributes.purpose,
                contactPerson: {
                    id: humanResourceSubmission.relationships.contactPerson.data.id,
                    label: humanResourceSubmission.relationships.contactPerson.data.attributes.fullname +
                        `${humanResourceSubmission.relationships.contactPerson.data.attributes.email
                            ? ` (${humanResourceSubmission.relationships.contactPerson.data.attributes.email})` : ''}`,
                    email: humanResourceSubmission.relationships.contactPerson.data.attributes.email,
                    phone: humanResourceSubmission.relationships.contactPerson.data.attributes.workPhoneNumber
                },
                contactPersonEmail: humanResourceSubmission.attributes.contactPersonEmail,
                phoneNumber: humanResourceSubmission.attributes.contactPersonPhoneNumber,
                creator: {
                    id: humanResourceSubmission.relationships.creator.data.relationships.user.data.id,
                    label: humanResourceSubmission.relationships.creator.data.relationships.user.data.attributes.fullname,
                    accountId: humanResourceSubmission.relationships.creator.data.id,
                    structuralUnitName: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.name : creatorProject.data.attributes.name
                },
                createdAt: (0, parsers_2.transformDate)(humanResourceSubmission.attributes.createdAt),
                dateOfConclusion: (0, parsers_2.transformDate)(humanResourceSubmission.attributes.dateOfConclusion)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseHumanResourceSubmission = parseHumanResourceSubmission;
const parseAddresseesAndPosition = (response) => {
    if (response.content === null)
        return [];
    const addressees = response.content;
    try {
        return addressees.data.map(account => {
            const userData = account.relationships.user.data;
            const contractData = account.relationships.structuralUnit.data;
            return {
                id: account.relationships.user.data.id,
                label: userData.attributes.fullname + (userData.attributes.email ? ` (${userData.attributes.email})` : ''),
                addresseeStructuralUnit: {
                    id: contractData.id,
                    accountId: account.id,
                    label: contractData.attributes.name
                }
            };
        });
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return [];
    }
};
exports.parseAddresseesAndPosition = parseAddresseesAndPosition;
const parseAssignSubmissionFiles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignSubmissionFile = response.content.data;
    try {
        return {
            data: (0, parsers_1.parseFileData)(assignSubmissionFile),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseAssignSubmissionFiles = parseAssignSubmissionFiles;
const parseHumanResourceSubmissionItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const items = response.content;
    try {
        return {
            data: items.data.map(el => {
                return {
                    id: el.id,
                    fullname: el.attributes.firstname + ' ' + el.attributes.lastname,
                    structuralUnits: el.relationships.positionInfo.data.map(item => item.relationships.structuralUnit.data.attributes.fullName) || null,
                    positions: el.relationships.positionInfo.data.map(item => item.attributes.position),
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt)
                };
            }),
            errors: null,
            meta: (0, parsers_2.transformPagination)(items.meta)
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseHumanResourceSubmissionItems = parseHumanResourceSubmissionItems;
const parseHRAssignSubmissionFormItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: parseHRAssignSubmissionFormItemData(items.data),
            errors: null,
            status: response.status,
            meta: (0, parsers_2.transformPagination)(items.meta)
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseHRAssignSubmissionFormItems = parseHRAssignSubmissionFormItems;
const parseHRAssignSubmissionFormItem = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: parseHRAssignSubmissionFormItemData(items.data),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_2.throwException)(err);
        return helper_2.errorObject;
    }
};
exports.parseHRAssignSubmissionFormItem = parseHRAssignSubmissionFormItem;
const parseFileType = (mediaFileData, fileType) => {
    return mediaFileData.filter(file => file.attributes.pivots.type === fileType).map(file => (0, exports.parseItemFile)(file));
};
exports.parseFileType = parseFileType;
const parseHRAssignSubmissionFormItemData = (items) => {
    return items.map(el => {
        const other = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.OTHER);
        const cv = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CV);
        const diploma = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.DIPLOMA);
        const passport = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.PASSPORT);
        const bankingRequisites = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES);
        const taxCertificate = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE);
        const conviction = (0, exports.parseFileType)(el.relationships.mediaFiles.data, types_1.AssignSubmissionItemFileTypes.CONVICTION);
        const assignSubmissionVerifyItems = el.relationships.submissionVerifyItems && el.relationships.submissionVerifyItems.data[0];
        return {
            id: el.id,
            documentId: el.attributes.documentId,
            citizen: el.attributes.foreignCitizen ? types_1.Citizen.FOREIGN : types_1.Citizen.GEORGIAN,
            personalNumber: el.attributes.personalNumber,
            passportNumber: el.attributes.passportNumber,
            legalAddress: el.attributes.legalAddress,
            physicalAddress: el.attributes.actualAddress,
            isDifferentAddress: el.attributes.legalAddress !== el.attributes.actualAddress,
            firstname: el.attributes.firstname,
            lastname: el.attributes.lastname,
            phoneNumber: el.attributes.phoneNumber,
            email: el.attributes.email,
            hasNoEmail: el.attributes.email === null,
            isEdocUser: el.attributes.isEdocUser,
            openCorporateEmail: el.attributes.openCorporateEmail,
            openBankAccount: el.attributes.openBankAccount,
            createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
            createdDateTimeAt: (0, parsers_2.transformDateTime)(el.attributes.createdAt),
            updatedAt: (0, parsers_2.transformDate)(el.attributes.updatedAt),
            positionInfos: el.relationships.positionInfo.data.map(position => {
                return {
                    ...(0, exports.parseEmployeePosition)(position, el.attributes.status),
                    position: position.relationships.structuralUnit.data.attributes.type === types_3.StructuralUnitType.STRUCTURAL_UNIT
                        ? position.attributes.position
                        : position.relationships.structuralUnit.data.attributes.name
                };
            }),
            files: {
                cv: cv.length > 0 ? cv : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CV }],
                diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.DIPLOMA }],
                passport: passport.length > 0 ? passport : [],
                bankingRequisites: bankingRequisites.length > 0 ? bankingRequisites : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.BANKING_REQUISITES }],
                taxCertificate: taxCertificate.length ? taxCertificate : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.TAX_CERTIFICATE }],
                conviction: conviction.length ? conviction : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.CONVICTION }],
                other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.AssignSubmissionItemFileTypes.OTHER }]
            },
            checked: false,
            assignSubmissionVerify: assignSubmissionVerifyItems && {
                id: assignSubmissionVerifyItems.relationships.document.data.id,
                number: assignSubmissionVerifyItems.relationships.document.data.attributes.number,
                status: assignSubmissionVerifyItems.relationships.document.data.attributes.status
            } || null
        };
    });
};
const parseEmployeePosition = (data, status) => {
    const majorPositionType = data.relationships.structuralUnit.data.attributes.type === types_3.StructuralUnitType.POSITION
        ? data.relationships.structuralUnit.data.relationships.positionType
        : data.relationships.positionType;
    const selectWorkDirection = data.relationships.workDirection;
    return {
        id: data.id,
        positionType: majorPositionType && {
            id: majorPositionType.data.id,
            label: majorPositionType.data.attributes.name
        },
        startDate: (0, parsers_2.transformDate)(data.attributes.startDate),
        minStartDate: status === types_1.AssignSubmissionVerifyItemStatuses.DRAFT ? (0, helper_1.getTomorrow)() : undefined,
        basicFunctionDuties: data.attributes.basicFunctionDuties,
        additionalInfo: data.attributes.additionalInfo,
        endDate: (0, parsers_2.nullableTransformDate)(data.attributes.endDate),
        structuralUnit: data.relationships.structuralUnit ? {
            id: data.relationships.structuralUnit.data.id,
            label: data.relationships.structuralUnit.data.attributes.type === types_3.StructuralUnitType.STRUCTURAL_UNIT ?
                data.relationships.structuralUnit.data.attributes.fullName
                : data.relationships.structuralUnit.data.relationships.parentUnit?.data.attributes.fullName,
            type: data.relationships.structuralUnit.data.attributes.type,
            positionStatus: data.relationships.structuralUnit.data.attributes.positionStatus,
            basicFunctionDuties: data.attributes.basicFunctionDuties
        } : null,
        workPlaces: data.relationships.workPlaces.data.map(workType => ({ id: workType.id, label: workType.attributes.nameKa })),
        workDirection: data.attributes.workDirection,
        selectWorkDirection: selectWorkDirection && {
            id: selectWorkDirection.data.id,
            label: selectWorkDirection.data.attributes.name
        },
        projectInfos: data.relationships.projects.data.length > 0
            ? data.relationships.projects.data.map(projectInfo => (0, exports.parseProjectInfos)(projectInfo))
            : [useCreateOrUpdateItems_1.initialProjectInfo],
        alert: (0, helper_1.countDays)(data.attributes.startDate, 1),
        hasProject: true,
        minDate: (0, helper_1.daysToAdd)((0, parsers_2.transformDate)(data.attributes.startDate), 1),
        maxContractEndDate: (0, helper_1.daysToSubtract)((0, parsers_2.nullableTransformDate)(data.attributes.endDate), 1)
    };
};
exports.parseEmployeePosition = parseEmployeePosition;
const parseEmployeePositionV2 = (data, position) => {
    const majorPositionType = data.relationships.structuralUnit.data.attributes.type === types_3.StructuralUnitType.POSITION
        ? data.relationships.structuralUnit.data.relationships.positionType
        : data.relationships.positionType;
    const selectWorkDirection = data.relationships.workDirection;
    return {
        id: data.id,
        positionType: majorPositionType && {
            id: majorPositionType.data.id,
            label: majorPositionType.data.attributes.name
        },
        startDate: (0, parsers_2.transformDate)(position.attributes.startDate),
        basicFunctionDuties: data.attributes.basicFunctionDuties,
        additionalInfo: data.attributes.additionalInfo,
        endDate: (0, parsers_2.nullableTransformDate)(position.attributes.endDate),
        structuralUnit: data.relationships.structuralUnit ? {
            id: data.relationships.structuralUnit.data.id,
            label: data.relationships.structuralUnit.data.attributes.type === types_3.StructuralUnitType.STRUCTURAL_UNIT ?
                data.relationships.structuralUnit.data.attributes.fullName
                : data.relationships.structuralUnit.data.relationships.parentUnit?.data.attributes.fullName,
            type: data.relationships.structuralUnit.data.attributes.type,
            positionStatus: data.relationships.structuralUnit.data.attributes.positionStatus,
            basicFunctionDuties: data.attributes.basicFunctionDuties
        } : null,
        workPlaces: [],
        workDirection: data.attributes.workDirection,
        selectWorkDirection: selectWorkDirection && {
            id: selectWorkDirection.data.id,
            label: selectWorkDirection.data.attributes.name
        },
        projectInfos: [useCreateOrUpdateItems_1.initialProjectInfo],
        alert: (0, helper_1.countDays)(data.attributes.startDate, 1),
        hasProject: false,
        minDate: null,
        maxContractEndDate: null
    };
};
exports.parseEmployeePositionV2 = parseEmployeePositionV2;
const parseProjectInfos = (projectInfo) => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const alertTime = projectInfo.relationships.project.data.attributes.endDate
        ? date > new Date(projectInfo.relationships.project.data.attributes.endDate)
        : false;
    return {
        id: projectInfo.id,
        countComments: projectInfo.attributes.countComments,
        project: {
            id: projectInfo.relationships.project.data.id,
            label: (0, parsers_3.parseProjectFullName)(projectInfo.relationships.project.data),
            alert: alertTime
                ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_2.nullableTransformDate)(projectInfo.relationships.project.data.attributes.endDate)
                : undefined
        },
        needsAssignVerify: projectInfo.relationships.project.data.attributes.needsAssignVerify
            ? types_2.NeedVerificationStatus.NEED_VERIFICATION
            : types_2.NeedVerificationStatus.DOES_NOT_NEED_VERIFICATION,
        workInfos: projectInfo.relationships.workInfos.data.map(workInfo => parseWorkTypesSalaryAmount(workInfo))
    };
};
exports.parseProjectInfos = parseProjectInfos;
const parseWorkTypesSalaryAmount = (workInfo) => {
    return {
        id: workInfo.id,
        workload: exports.workLoadOption.find(el => el.id === String(workInfo.attributes.workload)),
        salaryType: exports.salaryTypeOptions.find(el => el.id === String(workInfo.attributes.salaryType)),
        salaryAmount: workInfo.attributes.salaryAmount,
        currency: workInfo.relationships.currency && {
            id: workInfo.relationships.currency.data.id,
            label: workInfo.relationships.currency.data.attributes.code
        },
        equivalentCurrency: workInfo.relationships.equivalentCurrency && {
            id: workInfo.relationships.equivalentCurrency.data.id,
            label: workInfo.relationships.equivalentCurrency.data.attributes.code
        },
        salaryGivenType: exports.salaryGivenTypeOptions.find(el => el.id === String(workInfo.attributes.salaryIssuanceType)),
        workType: workInfo.attributes.salaryType === types_1.SalaryType.BY_JOB ? workInfo.relationships.workType && {
            id: workInfo.relationships.workType.data.id,
            label: workInfo.relationships.workType.data.attributes.name
        } : null,
        remunerationWork: exports.RemunerationWorkPerformedOptions.find(el => el.id === String(workInfo.attributes.remunerationWork)),
        fixedReservations: workInfo.attributes.salaryType === types_1.SalaryType.FIXED_RESERVATION
            ? workInfo.relationships.items.data.map(item => {
                return {
                    id: item.id,
                    isDate: !item.attributes.isDate,
                    isContractFullPeriod: item.attributes.isContractFullPeriod,
                    dateRange: item.attributes.isDate ? (0, parsers_2.nullableTransformDate)(item.attributes.startDate) + ' — ' + (0, parsers_2.nullableTransformDate)(item.attributes.endDate) : null,
                    months: !item.attributes.isDate && item.attributes.isContractFullPeriod
                        ? contsants_1.monthsWithAnnualOptions.flatMap(option => item.attributes.months.flatMap(month => Number(option.id) === month ? option : []))
                        : item.attributes.months && item.attributes.months.map(month => parseWorkInfoMonth((0, parsers_2.transformDate)(month.startDate), (0, parsers_2.transformDate)(month.endDate))),
                    salaryAmount: item.attributes.salaryAmount,
                    currency: {
                        id: item.relationships.currency.data.id,
                        label: item.relationships.currency.data.attributes.code
                    },
                    equivalentCurrency: {
                        id: item.relationships.equivalentCurrency.data.id,
                        label: item.relationships.equivalentCurrency.data.attributes.code
                    },
                    salaryGivenType: exports.salaryGivenTypeOptions.find(el => el.id === String(item.attributes.salaryIssuanceType)),
                    year: !item.attributes.isContractFullPeriod
                        ? parseWorkInfoYear(item.attributes.isDate, item.attributes.months)
                        : null,
                    isValidYear: true
                };
            })
            : [],
        supplements: workInfo.attributes.salaryType === types_1.SalaryType.SUPPLEMENT
            ? workInfo.relationships.items.data.map(item => {
                return {
                    id: item.id,
                    isDate: !item.attributes.isDate,
                    isContractFullPeriod: item.attributes.isContractFullPeriod,
                    dateRange: item.attributes.isDate ? (0, parsers_2.nullableTransformDate)(item.attributes.startDate) + ' — ' + (0, parsers_2.nullableTransformDate)(item.attributes.endDate) : null,
                    months: !item.attributes.isDate && item.attributes.isContractFullPeriod
                        ? contsants_1.monthsWithAnnualOptions.flatMap(option => item.attributes.months.flatMap(month => Number(option.id) === month ? option : []))
                        : item.attributes.months && item.attributes.months.map(month => parseWorkInfoMonth((0, parsers_2.transformDate)(month.startDate), (0, parsers_2.transformDate)(month.endDate))), supplementAmount: item.attributes.supplementAmount,
                    currency: {
                        id: item.relationships.currency.data.id,
                        label: item.relationships.currency.data.attributes.code
                    },
                    equivalentCurrency: {
                        id: item.relationships.equivalentCurrency.data.id,
                        label: item.relationships.equivalentCurrency.data.attributes.code
                    },
                    salaryGivenType: exports.salaryGivenTypeOptions.find(el => el.id === String(item.attributes.salaryIssuanceType)),
                    year: !item.attributes.isContractFullPeriod
                        ? parseWorkInfoYear(item.attributes.isDate, item.attributes.months)
                        : null,
                    isValidYear: true
                };
            })
            : [],
        remunerationWorks: workInfo.attributes.salaryType === types_1.SalaryType.BY_JOB
            && workInfo.attributes.remunerationWork == types_3.RemunerationWorkPerformed.FIXED_AMOUNT || workInfo.attributes.remunerationWork === types_3.RemunerationWorkPerformed.WORK_SUMMERY
            ? workInfo.relationships.items.data.map(item => {
                return {
                    id: item.id,
                    isDate: !item.attributes.isDate,
                    isContractFullPeriod: item.attributes.isContractFullPeriod,
                    dateRange: item.attributes.isDate ? (0, parsers_2.nullableTransformDate)(item.attributes.startDate) + ' — ' + (0, parsers_2.nullableTransformDate)(item.attributes.endDate) : null,
                    months: !item.attributes.isDate && item.attributes.isContractFullPeriod
                        ? contsants_1.monthsWithAnnualOptions.flatMap(option => item.attributes.months.flatMap(month => Number(option.id) === month ? option : []))
                        : item.attributes.months && item.attributes.months.map(month => parseWorkInfoMonth((0, parsers_2.transformDate)(month.startDate), (0, parsers_2.transformDate)(month.endDate))), salaryAmount: item.attributes.salaryAmount,
                    volumeWorkPerformed: item.attributes.volumeWorkPerformed,
                    currency: {
                        id: item.relationships.currency.data.id,
                        label: item.relationships.currency.data.attributes.code
                    },
                    equivalentCurrency: {
                        id: item.relationships.equivalentCurrency.data.id,
                        label: item.relationships.equivalentCurrency.data.attributes.code
                    },
                    salaryGivenType: exports.salaryGivenTypeOptions.find(el => el.id === String(item.attributes.salaryIssuanceType)),
                    year: !item.attributes.isContractFullPeriod
                        ? parseWorkInfoYear(item.attributes.isDate, item.attributes.months)
                        : null,
                    isValidYear: true
                };
            })
            : [],
        unitFees: workInfo.attributes.salaryType === types_1.SalaryType.BY_JOB && workInfo.attributes.remunerationWork === types_3.RemunerationWorkPerformed.UNIT_FEE
            ? workInfo.relationships.items.data.map(item => {
                const article = item.relationships.article;
                const administrativeOrder = article ? (article.data.relationships.administrativeOrder || article.data.relationships.correctionAdministrativeOrder) : null;
                return {
                    id: item.id,
                    unitName: exports.UnitNameOptions.find(el => el.id === String(item.attributes.unitType)),
                    amount: item.attributes.amount,
                    salaryAmount: null,
                    volumeWorkPerformed: item.attributes.volumeWorkPerformed,
                    tariff: article ? article.data.attributes.price : item.attributes.articlePrice,
                    currency: {
                        id: item.relationships.currency.data.id,
                        label: item.relationships.currency.data.attributes.code
                    },
                    equivalentCurrency: {
                        id: item.relationships.equivalentCurrency.data.id,
                        label: item.relationships.equivalentCurrency.data.attributes.code
                    },
                    salaryGivenType: exports.salaryGivenTypeOptions.find(el => el.id === String(item.attributes.salaryIssuanceType)),
                    administrativeOrdersArticle: article && {
                        id: article.data.id,
                        label: article.data.attributes.name + ' - ' + article.data.attributes.price + ' - ' + article.data.attributes.number + ' - '
                            + administrativeOrder.data.attributes.number + ' - '
                            + (0, parsers_2.transformDate)(administrativeOrder.data.attributes.publicationDate),
                        price: article.data.attributes.price
                    }
                };
            })
            : []
    };
};
const parseWorkInfoYear = (isDate, months) => {
    if (isDate)
        return new Date().getFullYear();
    const startDate = months && months[0]?.startDate && (0, parsers_2.transformDate)(months[0].startDate);
    if (startDate)
        return Number(startDate.split('-')[2]);
    return null;
};
const parseWorkInfoMonth = (startDate, endDate) => {
    const startMonth = startDate.split('-')[1]?.split('')[0] === '0'
        ? startDate.split('-')[1]?.split('')[1]
        : startDate.split('-')[1];
    const endMonth = endDate.split('-')[1]?.split('')[0] === '0'
        ? endDate.split('-')[1]?.split('')[1]
        : endDate.split('-')[1];
    return Number(startMonth) + 11 === Number(endMonth) ? contsants_1.monthsWithAnnualOptions[0] :
        {
            id: contsants_1.monthsOptions.find(el => el.id === startMonth).id,
            label: contsants_1.monthsOptions.find(el => el.id === startMonth).label
        };
};
const parseHumanResourceSubmissionItemStatusName = (status) => {
    switch (status) {
        case types_1.AssignSubmissionVerifyItemStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.AssignSubmissionVerifyItemStatuses.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.AssignSubmissionVerifyItemStatuses.CONFIRMED:
            return { name: 'დადასტურებული', color: 'success' };
        case types_1.AssignSubmissionVerifyItemStatuses.SUSPENDED:
            return { name: 'შეჩერებული', color: 'warning' };
        case types_1.AssignSubmissionVerifyItemStatuses.NEEDS_NO_VERIFY:
            return { name: 'არ მოწმდება', color: 'warning' };
        case types_1.AssignSubmissionVerifyItemStatuses.VERIFY_AT_PAYMENT_STAGE:
            return { name: 'გაკონტროლდება გადახდის ეტაპზე', color: 'success' };
        case types_1.AssignSubmissionVerifyItemStatuses.VERIFY_BY_BUDGETING_DEPT:
            return { name: 'გადამოწმდება ბიუჯეტირების მიერ', color: 'success' };
        default:
            return { name: 'პროექტი', color: 'secondary' };
    }
};
exports.parseHumanResourceSubmissionItemStatusName = parseHumanResourceSubmissionItemStatusName;
const parseHumanResourceSubmissionStatusName = (status) => {
    switch (status) {
        case types_1.HRAssignSubmissionStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.HRAssignSubmissionStatuses.SENT:
            return { name: 'გაგზავნილი', color: 'primary' };
        case types_1.HRAssignSubmissionStatuses.CONFIRMED:
            return { name: 'დადასტურებული', color: 'success' };
        case types_1.HRAssignSubmissionStatuses.DECLINED:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.HRAssignSubmissionStatuses.SENT_TO_VERIFY:
            return { name: 'გადამოწმების პროცესში', color: 'warning' };
        default:
            return { name: 'პროექტი', color: 'secondary' };
    }
};
exports.parseHumanResourceSubmissionStatusName = parseHumanResourceSubmissionStatusName;
const parseHumanResourceSubmissionSentBy = (sentBy) => {
    switch (sentBy) {
        case types_1.HRAssignSubmissionSentBy.SENT_BY_EDOC:
            return { name: 'E-Doc-ით', color: 'primary' };
        case types_1.HRAssignSubmissionSentBy.SENT_BY_CHANCELLERY:
            return { name: 'კანცელარიით', color: 'primary' };
    }
};
const parseItemFile = (file) => {
    return {
        id: file.id,
        type: file.attributes.pivots.type,
        name: file.attributes.name,
        status: file.attributes.status,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
exports.parseItemFile = parseItemFile;
const parseAddressee = (addressee) => {
    const structuralUnit = addressee.relationships.account.data.relationships.structuralUnit;
    const project = addressee.relationships.account.data.relationships.project;
    const accountData = addressee.relationships.account.data;
    const userData = accountData.relationships.user.data;
    return {
        id: userData.id,
        label: userData.attributes.fullname + (userData.attributes.email ? ` (${userData.attributes.email})` : ''),
        addresseeStructuralUnit: {
            id: structuralUnit ? structuralUnit.data.id : '',
            accountId: accountData.id,
            label: structuralUnit ? structuralUnit.data.attributes.name : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
        }
    };
};
exports.parseAddressee = parseAddressee;
exports.salaryTypeOptions = [
    { id: String(types_1.SalaryType.FIXED), label: 'ფიქსირებული' },
    { id: String(types_1.SalaryType.FIXED_RESERVATION), label: 'ფიქსირებული დათქმით' },
    { id: String(types_1.SalaryType.SUPPLEMENT), label: 'დანამატი' },
    { id: String(types_1.SalaryType.BY_JOB), label: 'შესასრულებელი სამუშაოს მიხედვით' },
    { id: String(types_1.SalaryType.UNPAID), label: 'ანაზღაურების გარეშე' }
];
exports.salaryGivenTypeOptions = [
    { id: String(types_1.SalaryGivenType.CONTRACT), label: 'ხელშეკრულებით' },
    { id: String(types_1.SalaryGivenType.INVOICE_AND_SUBMISSION), label: 'მიღება-ჩაბარებით/წარდგინებით' },
    { id: String(types_1.SalaryGivenType.INVOICE), label: 'მიღება-ჩაბარებით' },
    { id: String(types_1.SalaryGivenType.SUBMISSION), label: 'წარდგინებით' },
    { id: String(types_1.SalaryGivenType.ADMINISTRATIVE_ORDER), label: 'ბრძანებით' }
];
exports.workLoadOption = [
    { id: String(types_1.WorkLoad.FULL), label: 'სრული' },
    { id: String(types_1.WorkLoad.PARTIAL), label: 'არასრული' }
];
// export const positionTypeOptions = [
//   { id: String(PositionTypes.ACADEMIC), label: 'აკადემიური' },
//   { id: String(PositionTypes.ADMINISTRATIVE), label: 'ადმინისტრაციული' },
//   { id: String(PositionTypes.RESEARCH), label: 'კვლევითი' },
//   { id: String(PositionTypes.SUPPORT_STAFF), label: 'დამხმარე პერსონალი' },
//   { id: String(PositionTypes.STAFF_INVOLVED_LEARNING_PROCESS), label: 'სასწავლო პროცესში ჩართული პერსონალი' },
//   { id: String(PositionTypes.STAFF_INVOLVED_UNDER_GRUNT), label: 'გრანტის ფარგლებში მოწვეული პერსონალი' },
//   { id: String(PositionTypes.STAFF_INVOLVED_UNDER_PROJECT), label: 'პროექტის ფარგლებში მოწვეული პერსონალი' },
//   { id: String(PositionTypes.TEACHER), label: 'მასწავლებელი' }
// ]
exports.RemunerationWorkPerformedOptions = [
    { id: String(types_3.RemunerationWorkPerformed.FIXED_AMOUNT), label: 'ფიქსირებული (მოჭრილი ) თვის სარგო' },
    { id: String(types_3.RemunerationWorkPerformed.WORK_SUMMERY), label: 'მოწვევის ვადაზე ჯამურად შესასრულებელი სამუშაო მიხედვით' },
    { id: String(types_3.RemunerationWorkPerformed.UNIT_FEE), label: 'ერთეულის ტარიფი' }
];
exports.UnitNameOptions = [
    { id: String(types_3.UnitNames.PAGE), label: 'გვერდი' },
    { id: String(types_3.UnitNames.PERFORMANCE), label: 'სპექტაკლი' },
    { id: String(types_3.UnitNames.DAY), label: 'დღე' },
    { id: String(types_3.UnitNames.HOURS), label: 'საათი' },
    // { id: String(UnitNames.VIA_PRICE_LIST), label: 'პრეისკურანტით' },
    { id: String(types_3.UnitNames.ORDER_OR_IA_PRICE_LIST), label: 'პრეისკურანტით/ბრძანებით' }
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAuthorityReducer = exports.useUpdateAuthorityReducer = exports.initialAuthority = void 0;
const react_1 = require("react");
const initialAuthorityValues = {
    authorizer: null,
    deleteComment: null,
    endDate: '',
    startDate: ''
};
exports.initialAuthority = {
    data: initialAuthorityValues,
    errors: null,
    status: null,
    isUpdating: false,
    isDeleting: false,
    isFetching: false
};
const useUpdateAuthorityReducer = () => {
    return (0, react_1.useReducer)(exports.updateAuthorityReducer, exports.initialAuthority);
};
exports.useUpdateAuthorityReducer = useUpdateAuthorityReducer;
const updateAuthorityReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_UPDATE_AUTHORITY':
            return {
                ...state,
                errors: null,
                isUpdating: true
            };
        case 'DONE_UPDATE_AUTHORITY':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false
            };
        case 'SEND_AUTHORITY':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_AUTHORITY':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_DELETE_AUTHORITY':
            return {
                ...state,
                isDeleting: true,
                errors: null
            };
        case 'DONE_DELETE_AUTHORITY':
            return {
                ...state,
                errors: action.payload.errors,
                status: action.payload.status,
                isDeleting: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.updateAuthorityReducer = updateAuthorityReducer;

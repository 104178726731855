"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const authority_1 = require("src/modules/references/structuralUnitsUpgraded/store/authority");
const api_1 = require("src/api");
const helpers_1 = require("src/modules/references/structuralUnitsUpgraded/helpers");
const react_1 = require("react");
const useAuthority = (props) => {
    const [state, dispatch] = (0, authority_1.useUpdateAuthorityReducer)();
    const getAuthority = async (id) => {
        dispatch({ type: 'SEND_AUTHORITY' });
        const authority = await api_1.StructuralUnitsUpgradedApi.getAuthority(id);
        dispatch({ type: 'DONE_AUTHORITY', payload: authority });
    };
    const updateAuthority = async (id, values) => {
        const params = (0, helpers_1.transformUpdateAuthority)(values);
        dispatch({ type: 'SEND_UPDATE_AUTHORITY' });
        const authority = await api_1.StructuralUnitsUpgradedApi.updateAuthority(id, params);
        dispatch({ type: 'DONE_UPDATE_AUTHORITY', payload: authority });
        return authority;
    };
    const deleteAuthority = async (id, deleteComment) => {
        dispatch({ type: 'SEND_DELETE_AUTHORITY' });
        const authority = await api_1.StructuralUnitsUpgradedApi.deleteAuthority(id, deleteComment);
        dispatch({ type: 'DONE_DELETE_AUTHORITY', payload: authority });
        return authority;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.authorityId)
        getAuthority(props.authorityId).then(); }, []);
    return {
        state,
        closeErrorMessage,
        updateAuthority,
        deleteAuthority
    };
};
exports.default = useAuthority;

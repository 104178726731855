"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("src/api");
const react_1 = require("react");
const purchaseReport_1 = require("src/modules/documents/purchases/report/store/purchaseReport");
const usePurchaseReport = (props) => {
    const [state, dispatch] = (0, purchaseReport_1.usePurchaseReportReducer)();
    const getPurchaseReport = async (id) => {
        dispatch({ type: 'SEND_PURCHASE_REPORT_REQUEST' });
        const purchaseReport = await api_1.PurchaseReportApi.getPurchaseReport(id);
        dispatch({ type: 'DONE_PURCHASE_REPORT_REQUEST', payload: purchaseReport });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getPurchaseReport(props.id).then(); }, [props.id]);
    return { state, closeErrorMessage };
};
exports.default = usePurchaseReport;

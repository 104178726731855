"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.purchaseReportsReducer = exports.usePurchaseReportsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialPurchaseReport = {
    data: null,
    status: null,
    errors: null,
    filterForm: {},
    meta: store_1.initialMeta,
    isFetching: true,
    isFetched: true
};
const usePurchaseReportsReducer = () => {
    return (0, react_1.useReducer)(exports.purchaseReportsReducer, initialPurchaseReport);
};
exports.usePurchaseReportsReducer = usePurchaseReportsReducer;
const purchaseReportsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_REPORT_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_REPORT_REQUEST':
            return {
                ...state,
                data: action.payload.data || null,
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterForm: action.payload,
                isFetched: true
            };
        default:
            return state;
    }
};
exports.purchaseReportsReducer = purchaseReportsReducer;

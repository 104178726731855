"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurements = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const purchase_1 = require("../../../../common/helper/purchase");
const index_1 = require("api/index");
const procurements_1 = require("../store/procurements");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_1 = require("core/helpers");
const queryString_1 = require("core/helpers/queryString");
const helpers_2 = require("../helpers");
const helper_1 = require("../../../../common/helper");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useProcurements = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [state, dispatch] = (0, procurements_1.useProcurementsReducer)();
    const [deleteProcurementId, setDeleteProcurementId] = (0, react_1.useState)(null);
    const [showDeleteAlert, setShowDeleteAlert] = (0, react_1.useState)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [linkParams, setLinkParams] = (0, react_1.useState)('');
    const [procurementId, setProcurementId] = (0, react_1.useState)(null);
    const isMounted = (0, react_1.useRef)(false);
    const filter = (name, value, filterValues) => {
        getProcurements({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const debouncedSearch = (name, value, filterValues) => {
        (0, debouncedMethods_1.debouncedOnFunc)({ ...filterValues, [name]: value, page: 1 }, getProcurements);
    };
    const navigatePagination = (page, values) => {
        getProcurements({ ...values, page }).then();
    };
    const deleteProcurement = async (confirm, values) => {
        setShowDeleteAlert(false);
        if (!deleteProcurementId || !confirm)
            return setDeleteProcurementId(null);
        dispatch({ type: 'SEND_DELETE_REQUEST' });
        const procurements = await index_1.ProcurementsApi.deleteProcurement(deleteProcurementId);
        dispatch({ type: 'DONE_DELETE_REQUEST', payload: { errors: procurements.errors } });
        if (procurements.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getProcurements(values).then();
        setDeleteProcurementId(null);
    };
    const deleteAlert = (id) => {
        setShowDeleteAlert(true);
        setDeleteProcurementId(id);
    };
    const selectPerPage = (perPage, values) => {
        getProcurements({ ...values, perPage, page: 1 }).then();
    };
    const getProcurements = async (values) => {
        dispatch({ type: 'SEND_PROCUREMENTS_REQUEST' });
        const filterParams = (0, helpers_2.transformProcurementParamsToFilterParams)(values);
        const procurements = await index_1.ProcurementsApi.getProcurements(filterParams);
        if (procurements.data !== null)
            setLinkParams((0, queryString_1.stringify)((0, helpers_2.transformFilterParamsToUrl)(values), { encode: false }));
        if (isMounted?.current) {
            dispatch({ type: 'DONE_PROCUREMENTS_REQUEST', payload: procurements });
            navigate('/documents/purchases/procurements' + (0, queryString_1.stringify)((0, helpers_2.transformFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const procurementStatusOptions = (0, react_1.useMemo)(() => (0, helper_1.transformObjectForSelect)(purchase_1.ProcurementStatus), []);
    const initializeFilterForm = async () => {
        const params = (0, helpers_2.transformUrlToFilterParams)(location.search);
        if ((0, helpers_1.isAnyFilterValue)(params))
            setShowFilter(true);
        params.onlyMine = localStorage.getItem('procurementOnlyMine') || undefined;
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.procurementListing?.perPage;
        params.perPage = params.perPage || Number(storagePerPage) || 25;
        const procurementListId = (0, queryString_1.parse)(location.search).procurementId;
        getProcurements(params).then(() => dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: params }));
        setProcurementId(procurementListId);
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        initializeFilterForm().then();
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        debouncedSearch,
        deleteProcurement,
        deleteAlert,
        setShowFilter,
        showFilter,
        showDeleteAlert,
        deleteProcurementId,
        selectPerPage,
        filter,
        procurementStatusOptions,
        getProcurements,
        linkParams,
        procurementId
    };
};
exports.useProcurements = useProcurements;

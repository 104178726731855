"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSubmissionsForCorrection = exports.parseConditionalSubmissionItemsForSubmission = exports.parseExpirationDateHistory = exports.parseExpirationDateData = exports.parseChangeExpirationDate = exports.parseSubmissionItemTypeForProcurementCorrection = exports.parseSubmissionItemType = exports.parseSubmissionItemsForm = exports.parseSubmissionData = exports.parseSubmissionItemStatus = exports.parseSubmissionItems = exports.parseDeleteSubmission = exports.parseSubmissions = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/references/structuralUnitsUpgraded/types");
const types_2 = require("api/documents/purchases/submissionVerifies/types");
const parsers_1 = require("api/parsers/parsers");
const helper_1 = require("api/helper");
const parsers_2 = require("api/references/funding/projects/parsers");
const parsers_3 = require("api/documents/purchases/procurements/parsers");
const parseSubmissions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submissions = response.content;
    try {
        return {
            data: submissions.data.map(el => {
                const structuralUnit = el.relationships.account.data.relationships.structuralUnit;
                const project = el.relationships.account.data.relationships.project;
                return {
                    id: el.id,
                    number: el.attributes.number,
                    status: el.attributes.status,
                    alert: (0, parsers_1.checkSubmissionAlert)(el.attributes.executionDate),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt),
                    executionDate: (0, parsers_1.transformDate)(el.attributes.executionDate),
                    executionDateTime: (0, parsers_1.transformDateTime)(el.attributes.executionDate),
                    date: (0, parsers_1.transformDate)(el.attributes.date),
                    dateTime: (0, parsers_1.transformDateTime)(el.attributes.date),
                    expirationDate: (0, parsers_1.transformDate)(el.attributes.expirationDate),
                    expirationDateTime: (0, parsers_1.transformDateTime)(el.attributes.expirationDate),
                    author: el.relationships.account.data.relationships.user.data.attributes.fullname,
                    createdBy: el.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                    structuralUnit: structuralUnit
                        ? structuralUnit.data.attributes.name
                        : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    hasForceMajeureComment: el.attributes.hasForceMajeureComment
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(submissions.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissions = parseSubmissions;
const parseDeleteSubmission = (response) => {
    return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
};
exports.parseDeleteSubmission = parseDeleteSubmission;
const parseSubmissionItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submissionsItems = response.content;
    try {
        return {
            data: submissionsItems.data.map(el => {
                const currency = el.relationships.currency ? el.relationships.currency : null;
                const currencyRate = currency ? el.attributes.currencyExchangeRate || currency.data.attributes.rate : 1;
                const transformedRate = currencyRate && currency
                    ? currencyRate / (currency.data.attributes.quantity || 1)
                    : 1;
                return {
                    id: el.id,
                    moduleType: el.type,
                    subjectName: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
                    specification: el.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                    quantity: el.attributes.quantity,
                    price: el.attributes.price,
                    currencyExchangeRate: el.attributes.currencyExchangeRate || 1,
                    submissionStatus: el.relationships.document.data.attributes.status,
                    currency: currency ? {
                        id: currency.data.id,
                        label: currency.data.attributes.code,
                        currentExchangeRate: currency.data.attributes.rate,
                        quantity: currency.data.attributes.quantity,
                        transformedRate
                    } : null,
                    status: el.relationships.document.data.attributes.status,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(submissionsItems.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionItems = parseSubmissionItems;
const parseSubmissionItemStatus = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submissionsItems = response.content;
    try {
        return {
            data: submissionsItems.data.map(el => {
                return {
                    id: el.id,
                    quantity: el.attributes.quantity,
                    purchaseStatus: el.relationships.verifyItem ? el.relationships.verifyItem.data.attributes.purchaseStatus : types_2.SubmissionVerifyItemStatus.UNPREDICTED,
                    procurementTerminationReason: el.relationships.verifyItem ? el.relationships.verifyItem.data.attributes.procurementTerminationReason : null,
                    budgetingStatus: el.relationships.verifyItem ? el.relationships.verifyItem.data.attributes.budgetingStatus : types_2.SubmissionVerifyItemStatus.UNPREDICTED,
                    inPurchase: el.relationships.verifyItem !== null ? el.relationships.verifyItem.data.attributes.inPurchase : 0
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionItemStatus = parseSubmissionItemStatus;
const parseSubmissionData = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submission = response.content.data;
    try {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        const lastExpirationDate = submission.relationships.expirationDateHistory?.data.pop();
        const structuralUnit = submission.relationships.account.data.relationships.structuralUnit;
        const project = submission.relationships.account.data.relationships.project;
        return {
            data: {
                // items: submission.relationships.items.data.map(el => parseSubmissionItemType(el, date)),
                id: submission.id,
                hasForceMajeureComment: submission.attributes.hasForceMajeureComment,
                createdBy: submission.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                createdById: submission.relationships.conditionalSubmission?.data.relationships.creator.data.id || null,
                number: submission.attributes.number,
                conditionalSubmissionNumber: submission.relationships.conditionalSubmission?.data.attributes.number,
                date: (0, parsers_1.transformDate)(submission.attributes.date),
                status: submission.attributes.status,
                expirationDate: (0, parsers_1.transformDate)(submission.attributes.expirationDate),
                executionDate: (0, parsers_1.transformDate)(submission.attributes.executionDate),
                alert: (0, parsers_1.checkSubmissionAlert)(submission.attributes.executionDate),
                createdAt: (0, parsers_1.transformDate)(submission.attributes.createdAt),
                createdDateTimeAt: (0, parsers_1.transformDateTime)(submission.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(submission.attributes.updatedAt),
                employee: {
                    id: submission.relationships.account.data.id,
                    label: submission.relationships.account.data.relationships.user.data.attributes.fullname +
                        `${submission.relationships.account.data.relationships.user.data.attributes.email
                            ? ` (${submission.relationships.account.data.relationships.user.data.attributes.email})` : ''}`,
                    //TODO @Keti ბექისგან მოსაწოდებელია სტრუქტურული ერთეულები
                    structuralUnits: []
                },
                conditionalSubmissionId: submission.relationships.conditionalSubmission?.data.id,
                itemsCount: submission.relationships.conditionalSubmission?.data.attributes.itemsCount,
                remainingItemCount: submission.relationships.conditionalSubmission ?
                    submission.relationships.conditionalSubmission.data.attributes.itemsCount - submission.relationships.conditionalSubmission.data.attributes.usedItemsCount : null,
                structuralUnit: {
                    id: structuralUnit ? structuralUnit.data.id : '',
                    accountId: submission.relationships.account.data.id,
                    label: structuralUnit
                        ? structuralUnit.data.attributes.name
                        : 'პროექტის "' + project?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    type: structuralUnit?.data.attributes.type || types_1.StructuralUnitType.POSITION,
                    positionStatus: structuralUnit?.data.attributes.positionStatus || types_1.PositionStatus.FREELANCE
                    // positionType: structuralUnit?.data.attributes.positionType || PositionTypes.SUPPORT_STAFF
                },
                files: submission.relationships.mediaFiles?.data.map(file => {
                    return {
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        originalName: file.attributes.originalName,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension
                    };
                }),
                comment: lastExpirationDate?.attributes.comment || null,
                declineComment: lastExpirationDate?.attributes.declineComment || null,
                expirationDateId: lastExpirationDate?.id || null,
                expirationDateStatus: lastExpirationDate?.attributes.status || null,
                requiredExpirationDate: (0, parsers_1.nullableTransformDate)(lastExpirationDate?.attributes.expirationDate || null)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionData = parseSubmissionData;
const parseSubmissionItemsForm = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const items = response.content;
    try {
        return {
            data: items.data.map(item => (0, exports.parseSubmissionItemType)(item)),
            meta: items.meta ? (0, parsers_1.transformPagination)(items.meta) : undefined,
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSubmissionItemsForm = parseSubmissionItemsForm;
const parseSubmissionItemType = (el) => {
    const budgetArticle = el.relationships.budgetArticle?.data;
    const cpvCode = el.relationships.cpvCode?.data;
    const currency = el.relationships.currency?.data;
    const project = el.relationships.project;
    const dimensionUnit = el.relationships.dimensionUnit;
    const transformedRate = currency?.attributes.rate
        ? currency.attributes.rate / (currency.attributes.quantity || 1)
        : null;
    return {
        id: el.id,
        purchaseSubject: {
            subjectName: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
            specificationName: el.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
            categoryName: el.relationships.inventoryConfiguration.data.attributes.categoryTitle || '',
            verified: el.relationships.inventoryConfiguration.data.attributes.verified
        },
        assignment: el.attributes.assignment,
        inventoryConfigurationId: el.relationships.inventoryConfiguration.data.id,
        budgetArticle: budgetArticle && {
            id: budgetArticle.id,
            name: budgetArticle.attributes.name,
            code: budgetArticle.attributes.code,
            label: budgetArticle.attributes.code + ' - ' + budgetArticle.attributes.name
        },
        characteristics: el.attributes.characteristics,
        cpvCode: (cpvCode && {
            id: cpvCode.id,
            name: cpvCode.attributes.name,
            label: cpvCode.attributes.code + ' - ' + cpvCode.attributes.name,
            code: cpvCode.attributes.code
        }) ?? null,
        currencyExchangeRate: el.attributes.currencyExchangeRate,
        currency: currency ? {
            id: currency.id,
            label: currency.attributes.code,
            currentExchangeRate: currency.attributes.rate,
            quantity: currency.attributes.quantity,
            transformedRate: transformedRate || 1
        } : null,
        createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
        dimensionUnit: dimensionUnit && {
            id: dimensionUnit.data.id,
            label: dimensionUnit.data.attributes.name
        },
        gelSummery: currency && currency.attributes.code !== 'GEL'
            ? currency.attributes.quantity
                ? el.attributes.quantity * el.attributes.price * (el.attributes.currencyExchangeRate / currency.attributes.quantity)
                : null
            : el.attributes.quantity * el.attributes.price,
        price: el.attributes.price,
        project: project && (0, parsers_2.parseProjectSelectType)(project.data),
        quantity: el.attributes.quantity,
        originalQuantity: el.attributes.quantity,
        conditionalSubmissionItemId: el.relationships.conditionalSubmissionItem?.data.id || null,
        templateType: parsers_3.templateTypes.find(type => Number(type.id) === el.attributes.templateType) || null,
        bookLink: el.attributes.bookLink,
        bookTitle: el.attributes.bookTitle,
        bookIsbnCode: el.attributes.bookIsbnCode,
        bookAuthor: el.attributes.bookAuthor,
        inventoryConfigurationType: el.relationships.inventoryConfiguration.data.attributes.type
    };
};
exports.parseSubmissionItemType = parseSubmissionItemType;
const parseSubmissionItemTypeForProcurementCorrection = (el) => {
    const cpvCode = el.relationships.cpvCode?.data;
    const currency = el.relationships.currency?.data;
    const project = el.relationships.project;
    const transformedRate = currency?.attributes.rate
        ? currency.attributes.rate / (currency.attributes.quantity || 1)
        : null;
    return {
        id: el.id,
        // documentId,
        purchaseSubject: {
            subjectName: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
            specificationName: el.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
            categoryName: el.relationships.inventoryConfiguration.data.attributes.categoryTitle || '',
            verified: el.relationships.inventoryConfiguration.data.attributes.verified
        },
        inventoryConfigurationId: el.relationships.inventoryConfiguration.data.id,
        cpvCode: (cpvCode && {
            id: cpvCode.id,
            name: cpvCode.attributes.name,
            label: cpvCode.attributes.code + ' - ' + cpvCode.attributes.name,
            code: cpvCode.attributes.code
        }) ?? null,
        currencyExchangeRate: el.attributes.currencyExchangeRate,
        currency: currency ? {
            id: currency.id,
            label: currency.attributes.code,
            currentExchangeRate: currency.attributes.rate,
            quantity: currency.attributes.quantity,
            transformedRate: transformedRate || 1
        } : null,
        price: el.attributes.price,
        project: project && {
            id: project.data.id,
            label: (0, parsers_2.parseProjectFullName)(el.relationships.project.data),
            shortName: (0, parsers_2.parseProjectShortName)(el.relationships.project.data)
        },
        quantity: el.attributes.quantity,
        templateType: parsers_3.templateTypes.find(type => Number(type.id) === el.attributes.templateType) || null,
        bookLink: el.attributes.bookLink,
        bookTitle: el.attributes.bookTitle,
        bookIsbnCode: el.attributes.bookIsbnCode,
        bookAuthor: el.attributes.bookAuthor,
        inventoryConfigurationType: el.relationships.inventoryConfiguration.data.attributes.type,
        checked: true,
        disabled: true
    };
};
exports.parseSubmissionItemTypeForProcurementCorrection = parseSubmissionItemTypeForProcurementCorrection;
const parseChangeExpirationDate = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const data = response.content.data;
    const lastExpirationDate = data.relationships.expirationDateHistory.data.pop();
    try {
        return {
            data: {
                id: data.id,
                createdAt: (0, parsers_1.transformDate)(data.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(data.attributes.updatedAt),
                number: data.attributes.number,
                expirationDate: (0, parsers_1.transformDate)(data.attributes.expirationDate),
                executionDate: (0, parsers_1.nullableTransformDate)(data.attributes.executionDate),
                status: data.attributes.status,
                date: (0, parsers_1.transformDate)(data.attributes.date),
                comment: lastExpirationDate?.attributes.comment,
                expirationDateStatus: lastExpirationDate?.attributes.status,
                expirationDateId: lastExpirationDate?.id,
                requiredExpirationDate: lastExpirationDate?.attributes.expirationDate && (0, parsers_1.transformDate)(lastExpirationDate?.attributes.expirationDate) || null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseChangeExpirationDate = parseChangeExpirationDate;
const parseExpirationDateData = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const expirationDateData = response.content.data;
    try {
        const lastExpirationDateData = expirationDateData.pop();
        return {
            data: {
                id: lastExpirationDateData?.id,
                comment: lastExpirationDateData?.attributes.comment,
                declineComment: lastExpirationDateData?.attributes.declineComment,
                status: lastExpirationDateData?.attributes.status,
                expirationDate: (0, parsers_1.nullableTransformDate)(lastExpirationDateData?.attributes.expirationDate || null),
                executionDate: (0, parsers_1.nullableTransformDate)(lastExpirationDateData?.attributes.executionDate || null)
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseExpirationDateData = parseExpirationDateData;
const parseExpirationDateHistory = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const expirationDateHistories = response.content.data;
    try {
        return {
            data: expirationDateHistories.map(el => {
                return {
                    id: el.id,
                    expirationDate: (0, parsers_1.transformDate)(el.attributes.expirationDate),
                    executionDate: (0, parsers_1.transformDate)(el.attributes.executionDate),
                    oldExpirationDate: (0, parsers_1.transformDate)(el.attributes.oldExpirationDate),
                    oldExecutionDate: (0, parsers_1.transformDate)(el.attributes.oldExecutionDate),
                    creator: el.relationships.creator.data.attributes.fullname,
                    comment: el.attributes.comment,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt)
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseExpirationDateHistory = parseExpirationDateHistory;
const parseConditionalSubmissionItemsForSubmission = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmissionItem = response.content;
    try {
        return {
            data: conditionalSubmissionItem.data.map(item => {
                const project = item.relationships.project.data;
                const submissionItems = item.relationships.submissionItems && item.relationships.submissionItems.data[0];
                const alertTime = project.attributes.endDate ? new Date() > new Date(project.attributes.endDate) : false;
                return {
                    conditionalSubmissionItemId: item.id,
                    purchaseSubject: {
                        subjectName: item.relationships.inventoryConfiguration.data.attributes.itemTitle,
                        specificationName: item.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                        verified: item.relationships.inventoryConfiguration.data.attributes.verified
                    },
                    dimensionUnit: {
                        id: item.relationships.dimensionUnit.data.id,
                        label: item.relationships.dimensionUnit.data.attributes.name
                    },
                    quantity: item.attributes.quantity,
                    project: {
                        id: project.id,
                        label: (0, parsers_2.parseProjectFullName)(project, false),
                        shortName: (0, parsers_2.parseProjectShortName)(project),
                        alert: alertTime ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_1.nullableTransformDate)(project.attributes.endDate) : undefined
                    },
                    characteristics: item.attributes.characteristics,
                    assignment: item.attributes.assignment,
                    inventoryConfigurationId: item.relationships.inventoryConfiguration.data.id,
                    inventoryConfigurationType: item.relationships.inventoryConfiguration.data.attributes.type,
                    bookTitle: item.attributes.bookTitle,
                    bookAuthor: item.attributes.bookAuthor,
                    bookIsbnCode: item.attributes.bookIsbnCode,
                    bookLink: item.attributes.bookLink,
                    submission: submissionItems && {
                        id: submissionItems.relationships.document.data.id,
                        number: submissionItems.relationships.document.data.attributes.number,
                        status: submissionItems.relationships.document.data.attributes.status
                    } || null
                };
            }),
            meta: conditionalSubmissionItem.meta ? (0, parsers_1.transformPagination)(conditionalSubmissionItem.meta) : undefined,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissionItemsForSubmission = parseConditionalSubmissionItemsForSubmission;
const parseSubmissionsForCorrection = (response) => {
    if (response.content === null)
        return [];
    const submissions = response.content;
    try {
        return submissions.data.map(submission => {
            return {
                id: submission.id,
                label: submission.attributes.number
            };
        });
    }
    catch (e) {
        console.error(e);
        return [];
    }
};
exports.parseSubmissionsForCorrection = parseSubmissionsForCorrection;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuthorizedAccounts = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("api/references/authorizedAccounts/parsers");
const getAuthorizedAccounts = async (params) => {
    const response = await Api.get('documents/human-resources/authorized-accounts', params);
    return (0, parsers_1.parseAuthorizedAccounts)(response);
};
exports.getAuthorizedAccounts = getAuthorizedAccounts;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("src/api");
const authorities_1 = require("src/modules/references/authorities/store/authorities");
const react_1 = require("react");
const helpers_1 = require("core/helpers");
const queryString_1 = require("core/helpers/queryString");
const helpers_2 = require("src/modules/references/authorities/helpers");
const react_router_dom_1 = require("react-router-dom");
const useAuthorizedAccounts = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const isMounted = (0, react_1.useRef)(false);
    const [showFilter, setShowFilter] = (0, react_1.useState)(false);
    const [state, dispatch] = (0, authorities_1.useAuthorizedAccountsReducer)();
    const getAuthorizedAccounts = async (values) => {
        dispatch({ type: 'SEND_AUTHORIZED_ACCOUNTS_REQUEST' });
        const filterParams = (0, helpers_2.transformAuthorizedAccountParamsToFilterParams)(values);
        const authorizedAccounts = await api_1.AuthorizedAccountsApi.getAuthorizedAccounts(filterParams);
        if (isMounted?.current) {
            dispatch({ type: 'DONE_AUTHORIZED_ACCOUNTS_REQUEST', payload: authorizedAccounts });
            navigate('/references/authorized-accounts' + (0, queryString_1.stringify)((0, helpers_2.transformAuthorizedAccountFilterParamsToUrl)(values)), { replace: true });
        }
    };
    const getAuthorizedAccountsForStructuralUnit = async (values) => {
        dispatch({ type: 'SEND_AUTHORIZED_ACCOUNTS_REQUEST' });
        const authorizedAccounts = await api_1.AuthorizedAccountsApi.getAuthorizedAccounts({ ...values, filters: { authorizedForAccountStructuralUnitId: props.structuralUnitId } });
        if (isMounted?.current) {
            dispatch({ type: 'DONE_AUTHORIZED_ACCOUNTS_REQUEST', payload: authorizedAccounts });
        }
    };
    const initializeFilterForm = async () => {
        const params = (0, helpers_2.transformAuthorizedAccountUrlToFilterParams)(location.search);
        if ((0, helpers_1.isAnyFilterValue)(params))
            setShowFilter(true);
        getAuthorizedAccounts(params).then(() => dispatch({ type: 'INITIALIZE_AUTHORIZED_ACCOUNTS_FILTER_FORM', payload: params }));
    };
    const filter = (name, value, filterValues) => {
        getAuthorizedAccounts({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const navigatePagination = (page, values) => {
        props.structuralUnitId
            ? getAuthorizedAccountsForStructuralUnit({ ...values, page }).then()
            : getAuthorizedAccounts({ ...values, page }).then();
    };
    const selectPerPage = (perPage, values) => {
        dispatch({ type: 'CHANGE_PER_PAGE', payload: perPage });
        props.structuralUnitId
            ? getAuthorizedAccountsForStructuralUnit({ ...values, perPage, page: undefined })
            : getAuthorizedAccounts({ ...values, perPage, page: undefined }).then();
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        if (props.structuralUnitId) {
            getAuthorizedAccountsForStructuralUnit({}).then();
        }
        else {
            initializeFilterForm().then();
        }
        return () => { isMounted.current = false; };
    }, []);
    return {
        state,
        navigatePagination,
        selectPerPage,
        getAuthorizedAccounts,
        showFilter,
        filter,
        setShowFilter
    };
};
exports.default = useAuthorizedAccounts;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEmployeeForStructuralUnitSelect = exports.parseContract = exports.parseContracts = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseContracts = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors };
    const contracts = response.content;
    try {
        return {
            data: contracts.data.map(el => {
                const majorContractAttributes = el.relationships.accounts.data[0];
                return {
                    id: el.id,
                    endDate: (0, parsers_1.nullableTransformDate)(majorContractAttributes.attributes.endDate),
                    startDate: (0, parsers_1.transformDate)(majorContractAttributes.attributes.startDate),
                    status: el.attributes.status,
                    structuralUnitId: majorContractAttributes.relationships.structuralUnit.data.id,
                    userId: el.relationships.user.data.id
                };
            }),
            errors: null,
            meta: (0, parsers_1.transformPagination)(contracts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContracts = parseContracts;
const parseContract = (response, structuralUnitId) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contract = response.content;
    try {
        const account = contract.data.relationships.accounts.data.find(el => el.relationships.structuralUnit.data.id === structuralUnitId);
        if (!account)
            return { data: null, status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED, errors: [{ name: 'unexpected', message: 'მითითებულ თანამდებობაზე არ მოიძებნა დანიშვნა' }] };
        return {
            data: {
                id: contract.data.id,
                endDate: (0, parsers_1.nullableTransformDate)(contract.data.attributes.endDate),
                startDate: (0, parsers_1.transformDate)(contract.data.attributes.startDate),
                createdAt: (0, parsers_1.transformDate)(account.attributes.createdAt),
                updatedAt: (0, parsers_1.transformDate)(account.attributes.updatedAt),
                user: (0, exports.parseEmployeeForStructuralUnitSelect)(contract.data.relationships.user.data),
                structuralUnit: {
                    id: account.relationships.structuralUnit.data.id,
                    label: account.relationships.structuralUnit.data.attributes.fullName
                }
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContract = parseContract;
const parseEmployeeForStructuralUnitSelect = (employee) => {
    const identityNumber = employee.attributes.identityNumber ? ' (' + employee.attributes.identityNumber + ')' : '';
    return {
        id: employee.id,
        label: employee.attributes.fullname + identityNumber
    };
};
exports.parseEmployeeForStructuralUnitSelect = parseEmployeeForStructuralUnitSelect;

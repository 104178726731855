"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const structuralUnitPositionsAutocomplete_1 = require("../store/structuralUnitPositionsAutocomplete");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const react_1 = require("react");
const useStructuralUnitPositionsAutocomplete = () => {
    const [state, dispatch] = (0, structuralUnitPositionsAutocomplete_1.useStructuralUnitPositionsAutocompleteReducer)();
    const [name, setName] = (0, react_1.useState)(null);
    const getStructuralUnitPositionsAutocomplete = async (params) => {
        dispatch({ type: 'SEND_STRUCTURAL_UNITS_POSITION_AUTOCOMPLETE_REQUEST' });
        const PositionsAutocomplete = await index_1.StructuralUnitsUpgradedApi.getStructuralUnitPositionsAutocomplete(params);
        dispatch({ type: 'DONE_STRUCTURAL_UNITS_POSITION_AUTOCOMPLETE_REQUEST', payload: PositionsAutocomplete });
    };
    const resetAutocompleteData = (event) => {
        if (!event)
            return dispatch({ type: 'RESET_DATA' });
        const isFullName = document.querySelector('#autocomplete-listing')?.contains(event.target);
        if (!isFullName)
            dispatch({ type: 'RESET_DATA' });
    };
    const handleCreateStructuralUnitPosition = async (value) => {
        dispatch({ type: 'SEND_CREATE_STRUCTURAL_UNIT_POSITION_REQUEST' });
        const structuralUnitPosition = await index_1.StructuralUnitsUpgradedApi.createStructuralUnitPosition({ name: value });
        dispatch({ type: 'SEND_CREATE_STRUCTURAL_UNIT_POSITION_REQUEST' });
        return structuralUnitPosition;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const debouncedSearch = (value, inputName) => {
        if (!name || name !== inputName)
            setName(inputName || null);
        const params = value ? ({ filters: { keyword: value } }) : {};
        (0, debouncedMethods_1.debouncedOnFunc)(params, getStructuralUnitPositionsAutocomplete);
    };
    return { state, debouncedSearch, resetAutocompleteData, name, handleCreateStructuralUnitPosition, closeErrorMessage };
};
exports.default = useStructuralUnitPositionsAutocomplete;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseContractAndAnnexVisualSuvStatus = exports.parseContractAndAnnexVisualStatus = exports.parseBudgetingRecord = exports.parseContractsSigningListForModal = exports.parseSingleContractSigning = exports.parseContractsSigningList = void 0;
const types_1 = require("api/references/employees/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseContractsSigningList = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignContracts = response.content;
    try {
        return {
            data: assignContracts.data.map(el => parseContractData(el)),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(assignContracts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContractsSigningList = parseContractsSigningList;
const parseSingleContractSigning = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignContracts = response.content.data;
    const latestPdfFileUid = assignContracts.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
    const combinedMediaFileUid = assignContracts.relationships.combinedPdfMediaFile?.data.id;
    const corrections = assignContracts.relationships.corrections.data;
    try {
        return {
            data: {
                id: assignContracts.id,
                fullName: assignContracts.relationships.user.data.attributes.fullname,
                employeeId: assignContracts.relationships.user.data.id,
                contractFileUid: latestPdfFileUid || combinedMediaFileUid || null,
                contractStatus: assignContracts.attributes.status,
                contractCreatedShortDate: (0, parsers_1.transformDate)(assignContracts.attributes.createdAt),
                contractCreatedDateTime: (0, parsers_1.transformDateTime)(assignContracts.attributes.createdAt),
                contractUpdatedDateTime: (0, parsers_1.transformDateTime)(assignContracts.attributes.updatedAt),
                contractUpdatedShortDate: (0, parsers_1.transformDate)(assignContracts.attributes.updatedAt),
                signatoryIds: assignContracts.relationships.signatories.data
                    .map(el => el.relationships.userSignatory.data.relationships.user.data.id),
                contractStatusHistory: assignContracts.relationships.statusHistory.data.map(el => parseStatusHistory(el)),
                contractVisualStatus: (0, exports.parseContractAndAnnexVisualStatus)(assignContracts.attributes.status),
                isCorrection: false,
                corrections: corrections.length > 0
                    ? assignContracts.relationships.corrections.data.map(correction => {
                        const correctionLatestPdfFileUid = correction.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
                        const correctionCombinedMediaFileUid = correction.relationships.combinedPdfMediaFile?.data.id;
                        return {
                            id: correction.id,
                            fullName: assignContracts.relationships.user.data.attributes.fullname,
                            employeeId: assignContracts.relationships.user.data.id,
                            contractFileUid: correctionLatestPdfFileUid || correctionCombinedMediaFileUid || null,
                            contractStatus: correction.attributes.status,
                            contractCreatedShortDate: (0, parsers_1.transformDate)(correction.attributes.createdAt),
                            contractCreatedDateTime: (0, parsers_1.transformDateTime)(correction.attributes.createdAt),
                            contractUpdatedDateTime: (0, parsers_1.transformDateTime)(correction.attributes.updatedAt),
                            contractUpdatedShortDate: (0, parsers_1.transformDate)(correction.attributes.updatedAt),
                            signatoryIds: correction.relationships.signatories.data
                                .map(el => el.relationships.userSignatory.data.relationships.user.data.id),
                            contractStatusHistory: correction.relationships.statusHistory.data.map(el => parseStatusHistory(el)),
                            contractVisualStatus: (0, exports.parseContractAndAnnexVisualStatus)(correction.attributes.status),
                            corrections: [],
                            isCorrection: true
                        };
                    }) : []
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseSingleContractSigning = parseSingleContractSigning;
const parseContractData = (data) => {
    const corrections = data.relationships.corrections.data;
    const lastCorrection = corrections[corrections.length - 1];
    const latestPdfFileUid = lastCorrection
        ? lastCorrection.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id
        : data.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
    const combinedMediaFileUid = lastCorrection
        ? lastCorrection.relationships.combinedPdfMediaFile?.data.id
        : data.relationships.combinedPdfMediaFile?.data.id;
    return {
        id: data.id,
        userId: data.relationships.user.data.id,
        fullName: data.relationships.user.data.attributes.fullname,
        statusHistory: lastCorrection
            ? lastCorrection.relationships.statusHistory.data.map(el => parseStatusHistory(el))
            : data.relationships.statusHistory.data.map(el => parseStatusHistory(el)),
        contractFileUid: latestPdfFileUid || combinedMediaFileUid || null,
        contractStatus: data.attributes.status,
        contractVisualStatus: (0, exports.parseContractAndAnnexVisualStatus)(data.attributes.status),
        contractCorrectionVisualStatus: lastCorrection ? (0, exports.parseContractAndAnnexVisualStatus)(lastCorrection.attributes.status) : null,
        contractVisualSubStatus: (0, exports.parseContractAndAnnexVisualSuvStatus)(lastCorrection ? lastCorrection.attributes.subStatus : data.attributes.subStatus),
        creator: data.relationships.creator.data.relationships.user.data.attributes.fullname,
        creatorPosition: data.relationships.creator.data.relationships.structuralUnit?.data.attributes.name,
        createdShortDate: (0, parsers_1.transformDate)(lastCorrection ? lastCorrection.attributes.createdAt : data.attributes.createdAt),
        createdDateTime: (0, parsers_1.transformDateTime)(lastCorrection ? lastCorrection.attributes.createdAt : data.attributes.createdAt),
        updatedDateTime: (0, parsers_1.transformDateTime)(lastCorrection ? lastCorrection.attributes.updatedAt : data.attributes.updatedAt),
        updatedShortDate: (0, parsers_1.transformDate)(lastCorrection ? lastCorrection.attributes.updatedAt : data.attributes.updatedAt)
    };
};
const parseContractsSigningListForModal = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignContracts = response.content;
    try {
        return {
            data: assignContracts.data.map(el => {
                return {
                    id: el.id,
                    fullName: el.relationships.user.data.attributes.fullname,
                    visualStatus: (0, exports.parseContractAndAnnexVisualStatus)(el.attributes.status)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(assignContracts.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContractsSigningListForModal = parseContractsSigningListForModal;
const parseBudgetingRecord = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const assignContracts = response.content.data;
    try {
        return {
            data: {
                id: assignContracts.id,
                substatus: assignContracts.attributes.subStatus,
                recordedComments: assignContracts.relationships.contractFileRecordedItems.data.map(el => {
                    return {
                        comment: el.attributes.comment,
                        createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                        createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                        updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                        author: el.relationships.creator.data.relationships.user.data.attributes.fullname
                    };
                })
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBudgetingRecord = parseBudgetingRecord;
const parseContractAndAnnexVisualStatus = (status) => {
    switch (status) {
        case types_1.ContractAndAnnexStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.ContractAndAnnexStatuses.PENDING:
            return { name: 'გასაგზავნი', color: 'info' };
        case types_1.ContractAndAnnexStatuses.SENT:
            return { name: 'გაგზავნილი', color: 'primary' };
        case types_1.ContractAndAnnexStatuses.UNILATERALLY_SIGNED:
            return { name: 'ცალმხრივად ხელმოწერილი', color: 'warning' };
        case types_1.ContractAndAnnexStatuses.PARTIALLY_SIGNED:
            return { name: 'ნაწილობრივ ხელმოწერილი', color: 'warning' };
        case types_1.ContractAndAnnexStatuses.SIGNED:
            return { name: 'ორმხრივად ხელმოწერილი', color: 'success' };
        case types_1.ContractAndAnnexStatuses.STATUS_FAULTED:
            return { name: 'დახარვეზებული', color: 'danger' };
        case types_1.ContractAndAnnexStatuses.CORRECTED:
            return { name: 'ცვლილებით', color: 'warning' };
        default:
            return { name: 'პროექტი', color: 'secondary' };
    }
};
exports.parseContractAndAnnexVisualStatus = parseContractAndAnnexVisualStatus;
const parseContractAndAnnexVisualSuvStatus = (subStatus) => {
    switch (subStatus) {
        case types_1.ContractAndAnnexSubStatuses.PENDING:
            return { name: 'აღსარიცხი', color: 'secondary' };
        case types_1.ContractAndAnnexSubStatuses.RECORDED:
            return { name: 'აღრიცხული', color: 'success' };
    }
};
exports.parseContractAndAnnexVisualSuvStatus = parseContractAndAnnexVisualSuvStatus;
const parseStatusHistory = (el) => {
    return {
        id: el.id,
        statusChangeDateTime: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
        statusChangeShortDate: (0, parsers_1.transformDate)(el.attributes.createdAt),
        visualStatus: (0, exports.parseContractAndAnnexVisualStatus)(el.attributes.status),
        signatoryUserName: el.relationships.creator !== null
            ? el.relationships.creator.data.relationships.user.data.attributes.fullname
            : el.relationships.user.data.attributes.fullname
    };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthorityReducer = exports.useCreateAuthorityReducer = exports.initialCreateAuthority = void 0;
const react_1 = require("react");
const initialCreateAuthorityValues = {
    authorizeGranter: null,
    authorizerStructuralUnit: null,
    authorizer: null,
    endDate: '',
    orderDate: '',
    startDate: '',
    orderNumber: '',
    orderFile: null,
    comment: null
};
exports.initialCreateAuthority = {
    data: initialCreateAuthorityValues,
    errors: null,
    status: null,
    isCreating: false
};
const useCreateAuthorityReducer = () => {
    return (0, react_1.useReducer)(exports.createAuthorityReducer, exports.initialCreateAuthority);
};
exports.useCreateAuthorityReducer = useCreateAuthorityReducer;
const createAuthorityReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CREATE_AUTHORITY':
            return {
                ...state,
                errors: null,
                isCreating: true
            };
        case 'DONE_CREATE_AUTHORITY':
            return {
                ...state,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.createAuthorityReducer = createAuthorityReducer;

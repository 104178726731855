"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseStructuralUnitForAttach = exports.parseProjectsPerformers = exports.parseProjects = exports.parseAttachedUsers = exports.parseProject = exports.parseUserProjects = exports.parseProjectSelectType = exports.parseProjectShortName = exports.parseProjectFullName = exports.parseProjectsForSelect = void 0;
const types_1 = require("./types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseProjectsForSelect = (response, withBudgetSource) => {
    if (response.content === null)
        return [];
    const projects = response.content;
    const date = new Date();
    date.setDate(date.getDate() + 30);
    try {
        return projects.data.map(el => {
            const alertTime = el.attributes.endDate ? date > new Date(el.attributes.endDate) : false;
            return {
                id: el.id,
                label: (0, exports.parseProjectFullName)(el, withBudgetSource),
                shortName: (0, exports.parseProjectShortName)(el),
                disabled: el.attributes.endDate ? Date.now() > Date.parse(el.attributes.endDate) : false,
                alert: alertTime ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_1.nullableTransformDate)(el.attributes.endDate) : undefined
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseProjectsForSelect = parseProjectsForSelect;
const parseProjectFullName = (el, withBudgetSource = true) => {
    try {
        const number = el.attributes.number ? el.attributes.number + ' - ' : '';
        const name = el.attributes.name + ' - ';
        const additionalInfo = el.attributes.additionalInfo ? el.attributes.additionalInfo + ' - ' : '';
        const headOfDirection = (el.relationships.headOfDirection ? el.relationships.headOfDirection.data.attributes.fullname + ' - ' : '') || (el.attributes.headOfDirection ? el.attributes.headOfDirection + ' - ' : '');
        const startDate = el.attributes.startDate ? (0, parsers_1.transformDate)(el.attributes.startDate) + ' - ' : '';
        const endDate = el.attributes.endDate ? (0, parsers_1.transformDate)(el.attributes.endDate) : '';
        const donor = el.relationships.donor ? el.relationships.donor.data.attributes.name + ' - ' : '';
        const fundingSource = el.relationships.fundingSource.data.attributes.name + ' - ';
        const budgetSource = el.relationships.budgetSource.data.attributes.name + ' - ';
        return number + name + fundingSource + (withBudgetSource ? budgetSource : '') + additionalInfo + headOfDirection + donor + startDate + endDate;
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return el.attributes.name;
    }
};
exports.parseProjectFullName = parseProjectFullName;
const parseProjectShortName = (el) => {
    const fundingSource = el.relationships.fundingSource.data.attributes.name;
    return el.attributes.number ? el.attributes.number + ' - ' + fundingSource : fundingSource;
};
exports.parseProjectShortName = parseProjectShortName;
const parseProjectSelectType = (project) => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const alertTime = project.attributes.endDate ? date > new Date(project.attributes.endDate) : false;
    return {
        id: project.id,
        label: (0, exports.parseProjectFullName)(project),
        shortName: (0, exports.parseProjectShortName)(project),
        disabled: project.attributes.endDate ? Date.now() > Date.parse(project.attributes.endDate) : false,
        alert: alertTime ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_1.nullableTransformDate)(project.attributes.endDate) : undefined
    };
};
exports.parseProjectSelectType = parseProjectSelectType;
const parseUserProjects = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const userProjects = response.content;
    try {
        return {
            data: userProjects.data.map(el => el.id),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUserProjects = parseUserProjects;
const parseProject = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const project = response.content;
    const currentDate = new Date();
    try {
        const users = project.data.relationships.responsibleUsers.data.map(el => {
            return {
                id: el.id,
                label: el.attributes.fullname + ' (' + el.attributes.email + ')'
            };
        });
        const accounts = project.data.relationships.responsibleAccounts.data.map(el => {
            const structuralUnit = el.relationships.structuralUnit;
            const project = el.relationships.project;
            return {
                id: el.relationships.user?.data.id,
                label: el.relationships.user.data.attributes.fullname + '(' + el.relationships.user.data.attributes.email + ')',
                accountId: el.id,
                structuralUnitLabel: structuralUnit ? structuralUnit.data.attributes.fullName : project?.data.attributes.name
            };
        });
        return {
            data: {
                id: project.data.id,
                donor: (project.data.relationships.donor && {
                    id: project.data.relationships.donor.data.id,
                    label: project.data.relationships.donor.data.attributes.name
                }) || null,
                budgetSource: {
                    id: project.data.relationships.budgetSource.data.id,
                    label: project.data.relationships.budgetSource.data.attributes.name
                },
                fundingSource: {
                    id: project.data.relationships.fundingSource.data.id,
                    label: project.data.relationships.fundingSource.data.attributes.name,
                    type: project.data.relationships.fundingSource.data.attributes.type
                },
                additionalInfo: project.data.attributes.additionalInfo || '',
                headOfDirection: project.data.relationships.headOfDirection?.data.attributes.fullname || project.data.attributes.headOfDirection,
                headOfDirectionId: project.data.relationships.headOfDirection ? project.data.relationships.headOfDirection.data.id : null,
                coordinatorName: project.data.relationships.coordinator?.data.attributes.fullname || project.data.attributes.coordinatorName,
                coordinatorId: project.data.relationships.coordinator ? project.data.relationships.coordinator.data.id : null,
                needsAssignVerify: project.data.attributes.needsAssignVerify ? types_1.NeedVerificationStatus.NEED_VERIFICATION : types_1.NeedVerificationStatus.DOES_NOT_NEED_VERIFICATION,
                responsibles: [...accounts, ...users],
                name: project.data.attributes.name,
                number: project.data.attributes.number,
                startDate: (0, parsers_1.transformDate)(project.data.attributes.startDate),
                endDate: (0, parsers_1.nullableTransformDate)(project.data.attributes.endDate),
                isExpired: project.data.attributes.endDate ? currentDate > new Date(project.data.attributes.endDate) : false
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProject = parseProject;
const parseAttachedUsers = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const project = response.content;
    try {
        return {
            data: project.data.relationships.users?.data.map(el => el.id),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseAttachedUsers = parseAttachedUsers;
const parseProjects = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const projects = response.content;
    try {
        const warningDate = new Date();
        warningDate.setDate(warningDate.getDate() + 30);
        const currentDate = new Date();
        return {
            data: projects.data.map(el => {
                const users = el.relationships.responsibleUsers.data.map(el => {
                    return el.attributes.fullname;
                });
                const accounts = el.relationships.responsibleAccounts.data.map(el => {
                    return el.relationships.user.data.attributes.fullname;
                });
                return {
                    id: el.id,
                    moduleType: el.type,
                    additionalInfo: el.attributes.additionalInfo,
                    headOfDirection: el.relationships.headOfDirection?.data.attributes.fullname || el.attributes.headOfDirection,
                    coordinator: el.relationships.coordinator?.data.attributes.fullname || el.attributes.coordinatorName,
                    responsibles: [...accounts, ...users],
                    name: el.attributes.name,
                    number: el.attributes.number,
                    startDate: (0, parsers_1.transformDate)(el.attributes.startDate),
                    startDateTime: (0, parsers_1.transformDateTime)(el.attributes.startDate),
                    endDate: (0, parsers_1.nullableTransformDate)(el.attributes.endDate),
                    endDateTime: (0, parsers_1.transformDateTime)(el.attributes.endDate),
                    userFullNames: el.relationships.users.data.map(user => {
                        return {
                            id: user.id,
                            label: user.attributes.fullname
                        };
                    }),
                    checked: false,
                    hasPerformers: el.relationships.users.data.length > 0,
                    budgetSourceName: el.relationships.budgetSource.data.attributes.name,
                    fundingSourceName: el.relationships.fundingSource.data.attributes.name,
                    donorName: el.relationships.donor?.data.attributes.name,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    selected: false,
                    expireWarning: el.attributes.endDate ? warningDate > new Date(el.attributes.endDate) : false,
                    expireAlert: el.attributes.endDate ? currentDate > new Date(el.attributes.endDate) : false
                };
            }),
            status: response.status,
            errors: null,
            meta: projects.meta ? (0, parsers_1.transformPagination)(projects.meta) : undefined
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProjects = parseProjects;
const parseProjectsPerformers = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const projects = response.content;
    try {
        return {
            data: projects.data.map(el => {
                return {
                    userFullNames: el.relationships.users.data.map(item => {
                        return {
                            id: item.id,
                            label: item.attributes.fullname
                        };
                    }),
                    projectId: el.id
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseProjectsPerformers = parseProjectsPerformers;
const parseStructuralUnitForAttach = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const structuralUnits = response.content;
    try {
        return {
            data: {
                structuralUnit: structuralUnits.data.id
                    ? { id: structuralUnits.data.id, label: structuralUnits.data.attributes.name }
                    : null
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseStructuralUnitForAttach = parseStructuralUnitForAttach;

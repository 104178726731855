"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseLoginAs = exports.parseUser = exports.parseUsers = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseUsers = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const users = response.content;
    try {
        return {
            data: users.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    address: el.attributes.address,
                    birthDate: el.attributes.birthDate,
                    // edocUser: el.relationships.accounts.data.,
                    email: el.attributes.email,
                    emailVerifiedAt: el.attributes.emailVerifiedAt,
                    fathersName: el.attributes.fathersName,
                    firstname: el.attributes.firstname,
                    fullName: el.attributes.fullname,
                    gender: el.attributes.gender,
                    idCardValidTo: el.attributes.idCardValidTo,
                    identityNumber: el.attributes.identityNumber,
                    lastname: el.attributes.lastname,
                    mobileNumber: el.attributes.mobileNumber,
                    passportNumber: el.attributes.mobileNumber,
                    personalEmail: el.attributes.personalEmail,
                    status: el.attributes.status,
                    workPhoneNumber: el.attributes.workPhoneNumber,
                    positions: el.relationships.accounts.data.filter(el => !!el.relationships.structuralUnit).map(unit => {
                        const structuralUnit = unit.relationships.structuralUnit;
                        return {
                            id: structuralUnit.data.id,
                            type: structuralUnit.data.type,
                            status: unit.attributes.status,
                            isAuthorizedAccount: unit.attributes.IsAuthorizedAccount,
                            fullName: structuralUnit.data.attributes.name,
                            positionStatus: structuralUnit.data.attributes.positionStatus
                        };
                    }),
                    projects: el.relationships.accounts.data.filter(el => !!el.relationships.project).map(project => {
                        const userProject = project.relationships.project;
                        return {
                            id: userProject.data.id,
                            label: userProject.data.attributes.name
                        };
                    }),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(users.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUsers = parseUsers;
const parseUser = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const user = response.content;
    try {
        const country = user.data.relationships.country;
        return {
            data: {
                id: user.data.id,
                firstname: user.data.attributes.firstname,
                lastname: user.data.attributes.lastname,
                birthDate: (0, parsers_1.nullableTransformDate)(user.data.attributes.birthDate),
                idCardValidTo: (0, parsers_1.nullableTransformDate)(user.data.attributes.idCardValidTo),
                fathersName: user.data.attributes.fathersName,
                identityNumber: user.data.attributes.identityNumber,
                passportNumber: user.data.attributes.passportNumber,
                gender: user.data.attributes.gender,
                address: user.data.attributes.address,
                mobileNumber: user.data.attributes.mobileNumber,
                workPhoneNumber: user.data.attributes.workPhoneNumber,
                personalEmail: user.data.attributes.personalEmail,
                email: user.data.attributes.email,
                status: user.data.attributes.status,
                country: country ? { id: country.data.id, label: country.data.attributes.nameKa || country.data.attributes.nameEn } : null,
                password: user.data.attributes.password,
                password_confirmation: user.data.attributes.password_confirmation
                // edocUser: !!user.data.attributes.edocUser,
                //systemUser:user.data.attributes.systemUser
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseUser = parseUser;
const parseLoginAs = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const token = response.content;
    try {
        return {
            data: {
                accessToken: token.access_token,
                expiresInSeconds: token.expires_in,
                tokenType: token.token_type,
                refreshToken: token.refresh_token
            },
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseLoginAs = parseLoginAs;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFakeConditionalAssignSubmission = void 0;
const types_1 = require("api/documents/human-resources/assign-submissions/types");
const index_1 = require("api/index");
const types_2 = require("api/references/structuralUnitsUpgraded/types");
const fakeData_1 = require("../../helper/fakeData");
const helper_1 = require("../../../common/helper");
const employees_1 = require("api/references/employees");
const helpers_1 = require("core/helpers");
const structuralUnitsUpgraded_1 = require("api/references/structuralUnitsUpgraded");
const assign_submissions_1 = require("api/documents/human-resources/assign-submissions");
const helpers_2 = require("../../../documents/hr/assign-submissions/helpers");
const react_1 = require("react");
const context_1 = require("src/context/context");
const TranslationProvider_1 = require("src/providers/TranslationProvider");
const useFakeConditionalAssignSubmission = (props) => {
    const { translate } = (0, TranslationProvider_1.useTranslation)();
    const [isCreatingForButtonLoading, setIsCreatingForButtonLoading] = (0, react_1.useState)(false);
    const [isCreating, setIsCreating] = (0, react_1.useState)(false);
    const [isSendingForButtonLoading, setIsSendingForButtonLoading] = (0, react_1.useState)(false);
    const [isSending, setIsSending] = (0, react_1.useState)(false);
    const [isSendingForVerify, setIsSendingForVerify] = (0, react_1.useState)(false);
    const [isSendingForVerifyForButtonLoading, setIsSendingForVerifyForButtonLoading] = (0, react_1.useState)(false);
    const { toast } = (0, context_1.useToastContext)();
    const createConditionalAssignSubmission = async (buttonLoading = true) => {
        if (buttonLoading)
            setIsCreatingForButtonLoading(true);
        setIsCreating(true);
        const addressesAndPositions = await index_1.HumanResourcesSubmissionsApi.getAddresseesAndPosition();
        if (addressesAndPositions) {
            const employeesWithStructuralUnit = await (0, employees_1.getEmployeesForSelect)({});
            const filteredEmployees = employeesWithStructuralUnit.filter(el => el.structuralUnits.length > 0);
            if (employeesWithStructuralUnit) {
                const employeeAccountId = filteredEmployees[(0, helpers_1.getRandomIntBetween)(filteredEmployees.length, 0)]?.structuralUnits[0]?.accountId;
                const accountId = addressesAndPositions[0]?.addresseeStructuralUnit?.accountId;
                const modifiedData = { ...fakeData_1.data, requesterAccountId: employeeAccountId, addressees: [{ accountId: accountId }] };
                const assignSubmission = await index_1.HumanResourcesSubmissionsApi.createHRAssignSubmission(modifiedData);
                if (assignSubmission) {
                    toast.success(translate('hr_create_successfully', 'დანიშვნის პირობითი წარდგინება შეიქმნა წარმატებით'));
                    const onlyStructuralUnits = await (0, structuralUnitsUpgraded_1.getStructuralUnitsForSelect)({ filters: { type: types_2.StructuralUnitType.STRUCTURAL_UNIT }, perPage: 30 });
                    if (onlyStructuralUnits) {
                        const projectsData = await index_1.ProjectsApi.getProjects({});
                        const filteredProjects = projectsData.data.filter(el => !el.expireAlert);
                        const filteredProjectIds = filteredProjects.map(el => el.id);
                        const projectData = {
                            id: null,
                            projectId: filteredProjectIds[0],
                            workInfos: [fakeData_1.workInfosData]
                        };
                        const positionDataWithoutStructuralUnit = {
                            id: null,
                            structuralUnitId: null,
                            additionalInfo: null,
                            startDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToAdd)(null, null, 10)),
                            endDate: null,
                            basicFunctionDuties: 'test',
                            workPlaces: [String((0, helpers_1.getRandomIntBetween)(20, 1))],
                            projects: [projectData],
                            workDirection: null,
                            workDirectionId: null
                        };
                        const fullPositionData = { ...positionDataWithoutStructuralUnit, structuralUnitId: onlyStructuralUnits[0]?.id };
                        const modifiedItemsData = fakeData_1.itemsData.items.map(el => ({ ...el, positionInfo: [fullPositionData] }));
                        const conditionalAssignSubmission = await index_1.HumanResourcesSubmissionsApi.updateHRAssignSubmissionItems(assignSubmission.data?.id, { items: modifiedItemsData });
                        if (buttonLoading)
                            setIsCreatingForButtonLoading(false);
                        setIsCreating(false);
                        if (conditionalAssignSubmission) {
                            toast.success(translate('hr_subjects_successfully_added', 'დანიშვნის პირობით წარდგინებას წარმატებით დაემატა საგნები'));
                            return assignSubmission.data;
                        }
                        else {
                            toast.danger(translate('hr_subjects_failed_added', 'დანიშვნის პირობით წარდგინებას ვერ დაემატა საგნები'));
                            return null;
                        }
                    }
                }
                else {
                    if (buttonLoading)
                        setIsCreatingForButtonLoading(false);
                    setIsCreating(false);
                    toast.danger(translate('hr_create_failed', 'დანიშვნის პირობითი წარდგინება ვერ შეიქმნა წარმატებით'));
                    return null;
                }
            }
        }
        return null;
    };
    const sendConditionalAssignSubmission = async (buttonLoading = true) => {
        if (buttonLoading)
            setIsSendingForButtonLoading(true);
        const conditionalAssignSubmission = await createConditionalAssignSubmission(false);
        if (conditionalAssignSubmission && conditionalAssignSubmission.id) {
            setIsSending(true);
            const sentSubmission = await index_1.HumanResourcesSubmissionsApi.sendToChancellery(conditionalAssignSubmission.id);
            if (sentSubmission) {
                const params = (0, helpers_2.transformHRAssignSubmissionParams)(conditionalAssignSubmission);
                if (params) {
                    const temp = {
                        ...params,
                        edocNumber: 'hr-test-' + (0, helpers_1.getRandomIntBetween)(1000, 1),
                        requestRegistrationDate: (0, helper_1.transformParamsDate)((0, helper_1.dateToAdd)(null, null, 10)),
                        fileUids: [{ uid: props.uid, type: types_1.AssignSubmissionFileTypes.EDOC_DOCUMENT }]
                    };
                    const updatedSubmission = await index_1.HumanResourcesSubmissionsApi.updateHRAssignSubmission(conditionalAssignSubmission.id, temp);
                    if (buttonLoading)
                        setIsSendingForButtonLoading(false);
                    setIsSending(false);
                    if (updatedSubmission) {
                        toast.success(translate('hr_successfully_sent', 'დანიშვის პირობითი წარდგინება წარმატებით გაიგზავნა'));
                        return conditionalAssignSubmission;
                    }
                }
                else {
                    toast.danger(translate('hr_failed_sent', 'დანიშვის პირობითი წარდგინება წარმატებით ვერ გაიგზავნა'));
                    return null;
                }
            }
            else {
                setIsSending(false);
                return null;
            }
        }
        setIsSending(false);
        return null;
    };
    const sendForVerify = async (buttonLoading = true) => {
        if (buttonLoading)
            setIsSendingForVerifyForButtonLoading(true);
        const sentSubmission = await sendConditionalAssignSubmission(false);
        setIsSendingForVerify(true);
        if (sentSubmission && sentSubmission.id) {
            const sentForVerify = await (0, assign_submissions_1.sendProgressInVerify)(sentSubmission?.id);
            if (buttonLoading)
                setIsSendingForVerifyForButtonLoading(false);
            setIsSendingForVerify(false);
            if (sentForVerify) {
                toast.success(translate('hr_successfully_sent_for_verify', 'დანიშვის პირობითი წარდგინება წარმატებით გაიგზავნა გადამოწმებისთვის'));
                return sentSubmission;
            }
            else {
                toast.danger(translate('hr_failed_sent_for_verify', 'დანიშვის პირობითი წარდგინება წარმატებით ვერ გაიგზავნა გადამოწმებისთვის'));
                return null;
            }
        }
        if (buttonLoading)
            setIsSendingForVerifyForButtonLoading(false);
        setIsSendingForVerify(false);
        return null;
    };
    return {
        createConditionalAssignSubmission,
        isCreating,
        isCreatingForButtonLoading,
        sendConditionalAssignSubmission,
        isSending,
        isSendingForButtonLoading,
        isSendingForVerify,
        isSendingForVerifyForButtonLoading,
        sendForVerify
    };
};
exports.useFakeConditionalAssignSubmission = useFakeConditionalAssignSubmission;
